import { Component, Input, OnInit } from '@angular/core';
import formSections from './../../model/tax-documents-form.constant';
import formOptions from './../../model/tax-documents-options.constant';
import { TaxDocumentsForm } from '../../model/form.model';

@Component({
  selector: 'leaf-overview',
  template: `
    <mat-list *ngIf="taxDocumentsForm">
      <ng-container *ngFor="let section of formSections; let last = last">
        <div mat-subheader>{{ section.title }}</div>
        <mat-list-item *ngFor="let question of section.questions">
          <div mat-line>{{ question.title }}</div>
          <div *ngIf="question.type === 'amount'" mat-line> {{ taxDocumentsForm[question.controlName] }} </div>
          <div *ngIf="question.type === 'checklist'" mat-line>
            <ng-container *ngIf="taxDocumentsForm[question.controlName] as idList; else none">
              <ng-container *ngFor="let id of idList; let first = first">
                <ng-container *ngIf="!first">, </ng-container>
                {{ formOptions[id] }}
              </ng-container>
            </ng-container>
            <ng-template #none>Aucun élément coché</ng-template>
          </div>
          <div *ngIf="question.type === 'yes-or-no'" mat-line> {{ taxDocumentsForm[question.controlName] ? 'Oui' : 'Non' }} </div>
        </mat-list-item>
        <mat-divider *ngIf="!last"></mat-divider>
      </ng-container>
    </mat-list>
  `,
  styles: [
  ]
})
export class OverviewComponent implements OnInit {

  @Input() taxDocumentsForm: TaxDocumentsForm;

  formSections = formSections;
  formOptions = formOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
