import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(text: string, search: string | RegExp, replace: string): string {
    return text?.replace(search, replace);
  }
}

@Pipe({
  name: 'startsWith'
})
export class StartsWithPipe implements PipeTransform {

  transform(text: string, searchString: string): boolean {
    return text?.startsWith(searchString);
  }
}

@Pipe({
  name: 'substring'
})
export class SubstringPipe implements PipeTransform {

  transform(text: string, start: number): string {
    return text?.substring(start);
  }
}

@Pipe({
  name: 'breakLines'
})
export class BreakLinesPipe implements PipeTransform {

  transform(text: string): string {
    return text?.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
}
