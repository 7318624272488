import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormModule } from './form/form.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireFunctionsModule, USE_EMULATOR } from '@angular/fire/functions';
import { LogEffects } from './shared/store/log-effects.service';
import {
  APP_NAME_PROVIDER,
  COLLECTION_ENABLED_PROVIDER,
  CONFIG_PROVIDER,
  DEBUG_MODE_PROVIDER
} from './app.providers';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthenticationEffects } from './authentication/store/authentication.effects';
import { DatastoreEffects } from './datastore/store/datastore.effects';
import { FormEffects } from './form/store/form.effects';
import { MatButtonModule } from '@angular/material/button';
import { ChatModule } from './chat/chat.module';
import { DocumentsModule } from './documents/documents.module';
import { NavigationRailModule } from './navigation-rail/navigation-rail.module';
import { StringPipeModule } from './shared/string.module';
import { ArrayPipeModule } from './shared/array.module';
import { TaxHomeModule } from './tax-home/tax-home.module';
import { ActivationModule } from './activation/activation.module';
import { ActivationEffects } from './activation/store/activation.effects';
import { DocumentsEffects } from './documents/store/documents.effects';
import { TypePipeModule } from './shared/type.module';
import { UnavailableServiceModule } from './unavailable-service/unavailable-service.module';
import { NewsletterEffects } from './shared/store/newsletter.effects';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ContentModule } from './content/content.module';
import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { PaymentEffects } from './payment/store/payment.effects';
import { ProfileEffects } from './profile/store/profile.effects';
import { ProfileModule } from './profile/profile.module';
import { ValidationModule } from './validation/validation.module';
import { AdminModule } from './admin/admin.module';
import { AdminEffects } from './admin/store/admin.effects';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ActivationModule,
    AdminModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    ArrayPipeModule,
    AuthenticationModule,
    BrowserModule,
    BrowserAnimationsModule,
    ChatModule,
    ContentModule,
    DocumentsModule,
    EffectsModule.forRoot([
      ActivationEffects,
      AdminEffects,
      AuthenticationEffects,
      DatastoreEffects,
      DocumentsEffects,
      FormEffects,
      LogEffects,
      NewsletterEffects,
      PaymentEffects,
      ProfileEffects,
    ]),
    FontAwesomeModule,
    FormModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    NavigationRailModule,
    NgxStripeModule.forRoot(environment.stripe.publishableKey),
    ProfileModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false
      }
    }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    StringPipeModule,
    TaxHomeModule,
    TypePipeModule,
    UnavailableServiceModule,
    ValidationModule,
  ],
  providers: [
    CONFIG_PROVIDER,
    DEBUG_MODE_PROVIDER,
    APP_NAME_PROVIDER,
    COLLECTION_ENABLED_PROVIDER,
//    { provide: USE_EMULATOR, useValue: ['localhost', 5001] } // uncomment to use emulator
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
