<div *ngIf="documents && (activities$ | async) as activities" class="container">
  <leaf-activity *ngFor="let activity of activities"
                 [name]="activity.name"
                 status="{{ updatedDocumentsCount$ | async }} document(s) importé(s) sur {{ activity.idList.length }} documents requis"
                 [percent]="100 / activity.idList.length * (updatedDocumentsCount$  | async)"
                 [message]="requiredDocumentsCount ? requiredDocumentsCount + ' document(s) obligatoire(s) restant(s)' : ''"
                 [buttonText]="(taxValidated$ | async) ? 'Déclaration validée' : 'Valider la déclaration'"
                 [buttonDisabled]="requiredDocumentsCount !== 0 || (taxValidated$ | async)"
                 (buttonClick)="validateTax()"></leaf-activity>
  <p *ngIf="(taxValidated$ | async); else notValidated"
     class="message">Merci d'avoir validé votre déclaration, nous la traiterons dans un délai estimé à 15 jours.</p>
  <ng-template #notValidated>
    <p class="message">Voici les documents à nous faire parvenir afin d'optimiser au mieux votre déclaration d'impôts.</p>
  </ng-template>
  <h3>Vos documents</h3>
  <leaf-list [userId]="(user$ | async).userId"
             [columnsToDisplay]="columnsToDisplay"
             [columnsNames]="columnsNames"
             [documents]="documents"
             (observationChange)="observationChanged($event)"
             (uploadingStart)="uploadingStarted($event)"
             (uploadingSuccess)="uploadingSucceed($event)"
             (uploadingFail)="uploadingFailed($event)"></leaf-list>
</div>
