<mat-table
       [dataSource]="dataSource"
       multiTemplateDataRows
       matSort
       matSortActive="name"
       matSortDisableClear
       matSortDirection="asc">

  <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay; let i = index">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="document-attribut"> {{ columnsNames[i] }} </th>
    <td mat-cell *matCellDef="let document" class="document-attribut">
      <ng-container *ngIf="(document[column] | typeof:'number'); else NaN">{
        document[column],
        plural,
        =0 {Aucun fichier}
        =1 {1 fichier}
        other {{{document[column]}} fichiers}}
      </ng-container>
      <ng-template #NaN>
        <ng-container *ngIf="(document[column] | instanceofDate); else NaDate">
          {{ document[column] | date:'dd/MM/y' }}
        </ng-container>
      </ng-template>
      <ng-template #NaDate>
        <ng-container *ngIf="(document[column] === NO_FILE); else NaEnum">
          <span class="red">{{ document[column] }}</span>
        </ng-container>
      </ng-template>
      <ng-template #NaEnum>{{ document[column] }}</ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell class="expandedDetail" *matCellDef="let document" [attr.colspan]="columnsToDisplay.length">
      <mat-progress-bar *ngIf="document.status === UPLOADING && (uploadProgress$ | async) as uploadProgress"
                        [value]="uploadProgress" mode="determinate"></mat-progress-bar>
      <div class="document-detail" [ngClass]="expandedElement === document.id ? 'expanded' : 'collapsed'">
        <form class="document-detail-content"
              fxLayout="column" fxLayout.gt-sm="row"
              [formGroup]="pictureForm[document.id]">
          <div class="description" fxFlex="auto" fxFlex.gt-sm="50%">
            <h4>{{ document.workbook }}</h4>
            <p *ngIf="document.required; else notRequired" class="required">document requis</p>
            <ng-template #notRequired><p class="required">non obligatoire</p></ng-template>
            <p *ngIf="document.description; else noDesc">{{ document.description }}</p>
            <ng-template #noDesc><p>Aucune description disponible</p></ng-template>
            <div *ngIf="document.files.length">
              <h5>{ document.files.length, plural, =1 {Fichier importé} other {Fichiers importés}}</h5>
              <ul>
                <li *ngFor="let documentFile of document.files"><a [href]="documentFile.path" target="_blank">{{ documentFile.name }}</a></li>
              </ul>
            </div>
            <textarea formControlName="observation" placeholder="Observations" (change)="observationChanged(document.id)"></textarea>
            <p class="observation-status">{{ document.observationStatus }}</p>
          </div>
          <div class="separator"></div>
          <div class="upload" fxFlex="auto" fxFlex.gt-sm="50%">
            <mat-form-field class="drop-zone"
                            appearance="none"
                            leafDragAndDrop
                            (filesDropped)="onFilesDropped(document.id, $event)">
              <mat-label>Importation des documents</mat-label>
              <div class="upload-info">
                <mat-error *ngIf="pictureForm[document.id].controls['files'].hasError('image')">
                  Il ne s'agit pas d'un format de document valide
                </mat-error>
                <p>Taille maximum 24 MB</p>
                <p>Formats supportés PDF, JPG et PNG</p>
              </div>
              <ngx-mat-file-input
                accept="application/pdf,image/jpeg,image/png"
                formControlName="files"
                placeholder="Importation des documents"
                (change)="uploadFile(document.id)" multiple>
              </ngx-mat-file-input>
            </mat-form-field>
          </div>
        </form>
      </div>
    </td>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
  <mat-row *matRowDef="let document; columns: columnsToDisplay;"
      class="document-row"
      (click)="expandedElement = expandedElement === document.id ? null : document.id">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="document-detail-row"></mat-row>
</mat-table>
