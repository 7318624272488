import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot
} from '@angular/fire/firestore';
import { TaxDocumentsForm, TaxProfileForm } from '../../form/model/form.model';
import { User } from '../../authentication/model/user.model';
import { from, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Profile } from '../../profile/model/profile.model';
import { ImportedDoc, Observation } from '../../documents/model/documents.model';

/*
* collection names have to be authorized in Firestore rules:
* https://console.firebase.google.com/project/myleaf-4l1v3/firestore/rules
 */
const importedDoc = environment.datastore.importedDoc;
const observation = environment.datastore.observation;
const taxProfile = environment.datastore.taxProfile;
const taxDocuments = environment.datastore.taxDocuments;
const profile = environment.datastore.profile;


@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  constructor(
    private afs: AngularFirestore,
  ) {
  }

  saveProfileData(data: Profile, user: User): Observable<void> {
    return this.setDataInCollectionForUser<Profile>(data, profile, user);
  }

  saveImportedDocData(data: ImportedDoc): Observable<string> {
    return from(this.afs.collection(importedDoc).add({...data}).then(docRef => docRef.id));
  }

  saveObservationData(data: Observation): Observable<string> {
    return this.afs.collection<Observation>(observation, ref =>
      ref.where('userId', '==', data.userId).where('documentId', '==', data.documentId)
    ).get().pipe(
      mergeMap(querySnapShot => {
        if (querySnapShot.empty) {
          this.afs.collection(observation).add({...data});
        }
        else {
          const docRef = this.afs.collection(observation).doc<Observation>(querySnapShot.docs[0].id);
          docRef.set(Object.assign({}, data));
        }
        return of(data.documentId);
      })
    );
  }

  saveTaxProfileData(data: TaxProfileForm, user: User): Observable<void> {
    return this.setDataInCollectionForUser<TaxProfileForm>(data, taxProfile, user);
  }

  saveTaxDocumentsData(data: TaxDocumentsForm, user: User): Observable<void> {
    return this.setDataInCollectionForUser<TaxDocumentsForm>(data, taxDocuments, user);
  }

  private setDataInCollectionForUser<T>(data: T, collection: string, user: User) {
    this.checkDataAndUser(data, user);
    const docRef = this.afs.collection(collection).doc<T>(user.userId);
    return from(docRef.set(data));
  }

  private checkDataAndUser(data: any, user: User) {
    if (user === null) {
      throw Error('not logged in');
    }
    if (data === null) {
      throw Error('no data to save');
    }
  }

  loadProfileData(user: User): Observable<Profile> {
    const fieldNameList = Object.keys(Reflect.construct(Profile, []));
    return this.getCollectionForUser<Profile>(profile, user).pipe(
      filter(documentReference => documentReference.exists),
      map(this.getDataForClass<Profile>(fieldNameList))
    );
  }

  loadTaxProfileData(user: User): Observable<TaxProfileForm> {
    const fieldNameList = Object.keys(Reflect.construct(TaxProfileForm, []));
    return this.getCollectionForUser<TaxProfileForm>(taxProfile, user).pipe(
      filter(documentReference => documentReference.exists),
      map(this.getDataForClass<TaxProfileForm>(fieldNameList))
    );
  }

  loadTaxDocumentsData(user: User): Observable<TaxDocumentsForm> {
    const fieldNameList = Object.keys(Reflect.construct(TaxDocumentsForm, []));
    return this.getCollectionForUser<TaxDocumentsForm>(taxDocuments, user).pipe(
      filter(documentReference => documentReference.exists),
      map(this.getDataForClass<TaxDocumentsForm>(fieldNameList))
    );
  }

  loadImportedDocData(user: User): Observable<ImportedDoc[]> {
    const fieldNameList = Object.keys(Reflect.construct(ImportedDoc, []));
    return this.queryCollectionForUser<ImportedDoc>(importedDoc, user).pipe(
      map(querySnapShot => querySnapShot.docs.map(this.getDataForClass<ImportedDoc>(fieldNameList)))
    );
  }

  loadObservationData(user: User): Observable<Observation[]> {
    const fieldNameList = Object.keys(Reflect.construct(Observation, []));
    return this.queryCollectionForUser<Observation>(observation, user).pipe(
      map(querySnapShot => querySnapShot.docs.map(this.getDataForClass<Observation>(fieldNameList)))
    );
  }

  private getCollectionForUser<T>(collection: string, user: User): Observable<DocumentSnapshot<T>> {
    if (user === null) {
      throw Error('not logged in');
    }
    return this.afs.collection<T>(collection).doc(user.userId).get() as Observable<DocumentSnapshot<T>>;
  }

  private queryCollectionForUser<T>(collection: string, user: User): Observable<QuerySnapshot<T>> {
    if (user === null) {
      throw Error('not logged in');
    }
    return this.afs.collection<T>(collection, ref => ref.where('userId', '==', user.userId)).get();
  }

  // TODO to test
  private getDataForClass<T>(fieldNameList: string[]) {
    return (data: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>): T =>
      fieldNameList.reduce((acc: T, fieldName: string) => ({...acc, [fieldName]: data.get(fieldName)}), {} as T);
  }
}
