import { Action, createReducer, on } from '@ngrx/store';
import { Profile } from '../model/profile.model';
import { getProfileSuccess, refreshProfileSuccess } from '../../datastore/store/datastore.actions';
import { signOutSuccess } from '../../authentication/store/authentication.actions';
import { taxValidated } from '../../documents/store/documents.actions';

export default interface ProfileState {
  profile: Profile;
}

export const initialState: ProfileState = {
  profile: null,
};

const resultReducer = createReducer(
  initialState,
  on(
    getProfileSuccess,
    refreshProfileSuccess,
    (state, action) => (
      {...state, profile: action.profile}
    )),
  on(
    taxValidated,
    (state) => (
      {...state, profile: {...state.profile, taxValidated: true, taxValidationDate: new Date()}}
    )),
  on(
    signOutSuccess,
    (state) => (
      {...state, profile: null}
    )),
);

export function reducer(state: ProfileState | undefined, action: Action) {
  return resultReducer(state, action);
}
