<div class="terms">
  <h1>Conditions générales & Politique de confidentialité</h1>
  <p class="disclaimer">CONSULTEZ CE DOCUMENT AVANT L’UTILISATION DU SERVICE,
    SI VOUS N’ACCEPTEZ PAR LES PRÉSENTES CONDITIONS GÉNÉRALES & LA POLITIQUE DE CONFIDENTIALITÉ N’UTILISEZ PAS LE SERVICE.
  </p>
  <h2>Conditions générales d'utilisation</h2>
  <h3>Introduction</h3>
  <ul>
    <li>LEAF Sàrl est une société organisée selon le droit suisse.</li>
    <li>Ce document contient les règles de base de l’utilisation de LEAF (https://leaf.swiss)
      et de la politique de protection des données.</li>
    <li>En accédant au site ou en utilisant ses services, vous consentez au présent document.</li>
  </ul>
  <h3>Modifications</h3>
  <ul>
    <li>LEAF se réserve le droit de modifier à tout instant, et à sa seule discrétion, les conditions générales d’utilisation
      et la politique de protection des données.</li>
    <li>L’utilisation continue de ce site à la suite de modifications constitue un consentement à ces changements.</li>
    <li>Le présent document prévaut sur tout accord verbal et ne peut être modifié que par accord écrit émanant de LEAF.</li>
  </ul>
  <h3>For et droit applicable</h3>
  <ul>
    <li>Le for est sis en Suisse à Genève, sans égards aux règles de conflits de loi qui pourraient trouver application
      provenant d’une autre juridiction.</li>
    <li>Le droit applicable est le droit suisse.</li>
    <li>Si pour quelque raison que cela soit, la Cour compétente estime certaines provisions inapplicables en tout ou en partie,
      le reste des termes continuera à déployer tous ses effets.</li>
    <li>Pour toute dispute ou tout événement qui naîtrait de l’utilisation ou en relation avec le présent site web,
      vous acceptez la compétence exclusive des tribunaux cantonaux genevois, respectivement celle des tribunaux fédéraux suisses,
      et l’application du droit suisse.</li>
  </ul>
  <h3>Limite de responsabilité & indemnisation</h3>
  <ul>
    <li>LEAF est fourni "as is" et "as available". Il ne fait aucune représentation ou garantie de quelque nature que ce soit,
      expresse ou implicite, quant au fonctionnement du site ou aux informations, contenus ou matériels inclus sur ce site
      ou postés par les administrateurs, dans toute la mesure permise par la loi applicable.</li>
    <li>LEAF décline toute garantie, expresse ou implicite, y compris, mais sans s'y limiter,
      aux garanties implicites de qualité marchande et d'adéquation à un usage particulier.</li>
    <li>LEAF ne sera pas tenue responsable des dommages de quelque nature que ce soit découlant de l'utilisation
      ou de l'incapacité d'utiliser ce site. Vous acceptez expressément d'utiliser ce site uniquement à vos propres risques.
      En aucun cas LEAF ou ses intermédiaires ne seront responsables de tout dommage direct, spécial, accessoire, indirect, économique
      ou consécutif (y compris, mais sans s'y limiter, la perte de données ou les dommages liés à la perte d'utilisation
      ou de bénéfices) découlant de ou lié au site (y compris, mais sans s'y limiter, son fonctionnement, son contenu
      ou les informations ou matériels qu'il contient, ou l'utilisation ou l'incapacité d'utiliser tout autre site lié au site,
      ou tout contenu de ce site, ou les présentes conditions, ou la politique de confidentialité applicable à ce site) même si LEAF,
      ses intermédiaires ont été informés de la possibilité d’un dommage.</li>
    <li>En ce qui concerne les liens présents sur le site renvoyant à un site interne ou externe, vous acceptez le fait que
      LEAF ne porte pas de responsabilité pour le contenu auquel vous accédez ou des dommages causés à partir de son propre site
      ou d'un site tiers.</li>
    <li>La responsabilité respective de LEAF, de ses intermédiaires est limitée dans la plus grande mesure permise
      par la loi applicable.</li>
    <li>Tout utilisateur reconnait et accepte d'indemniser et de dégager LEAF, ses filiales, ses dirigeants, ses employés et ses agents,
      de toute responsabilité, y compris les coûts et les frais d'avocats, de toute réclamation ou demande faite par un tiers en raison
      de ou découlant de votre utilisation de LEAF ou des Services, de votre violation du présent document, ou de la violation par vous
      ou faite sous votre compte, de toute propriété intellectuelle ou autre droit de toute personne ou entité.
    </li>
    <li>Vous déclarez et garantissez que vous n'utiliserez aucun logiciel de type virus qui pourraient être nocifs,
      comme par exemple qui envoie des requêtes répétées à LEAF, ou qui envoie des requêtes automatisées de quelque nature que ce soit.
    </li>
    <li>Le fait que LEAF n'exerce pas ou ne fasse pas respecter un droit ou une disposition du présent document ne doit pas
      être considéré comme une renonciation à ce droit ou à cette disposition.
    </li>
  </ul>
  <h3>Droits de propriété intellectuelle</h3>
  <ul>
    <li>Tous les documents et médias, par exemple - mais non limité - aux marques, noms, titres, logos, images, graphismes, textes
      et autres éléments, présents sur le site web sont la propriété de LEAF ou sont reproduits avec la permission d'autres propriétaires
      de droits de propriété intellectuelle. Tous les droits sont réservés.</li>
    <li>Les documents présents sur ce site web peuvent être récupérés et téléchargés uniquement à des fins non-commerciales, sauf accord
      écrit préalable. Aucun autre matériel ne peut être copié, modifié, publié, diffusé ou distribué sans l'autorisation du propriétaire.
    </li>
  </ul>
  <h2>Politique de protection des données</h2>
  <h3>Traitement des données</h3>
  <ul>
    <li>Lorsque vous naviguez (surfez), lisez ou téléchargez des informations sur le site LEAF, des données peuvent être recueillies,
      notamment votre adresse IP, la requête URL, le type de navigateur, ainsi que la date et l'heure de votre requête.</li>
    <li>Le but de la collection de ces données est d’améliorer nos services ainsi que l’expérience utilisateur, comme par exemple
      au travers de - mais non limité à – l’analyse d’audience (p.ex. via le programme Google Analytics). Partant, vous acceptez que
      les données relevées à votre sujet puissent être retransmises à un tiers, comme un serveur de Google basé aux Etats-Unis,
      où elles pourraient être enregistrées et traitées.</li>
  </ul>
  <h3>Conservation des données</h3>
  <ul>
    <li>Le site met en place des mesures afin de sécuriser les données que nous traitons.
      Toutefois, aucune garantie de confidentialité ne peut être assurée par LEAF.</li>
    <li>LEAF ne pourra pas être tenu responsable pour les préjudices que vous ou toute autre personne pourriez subir suite à une violation
      de vos données dans votre utilisation de LEAF ou d’un site auquel il amène via un lien externe.</li>
  </ul>
  <button mat-button (click)="back()">Retour</button>
</div>
