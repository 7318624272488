import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getPrice } from '../activation/store/activation.selector';
import { Observable, Subscription } from 'rxjs';
import { User } from '../authentication/model/user.model';
import { getAuthUser } from '../authentication/store/authentication.selector';
import { State } from '../reducers';
import { Activity } from '../documents/model/documents.model';
import { getActivities, getImportedDocumentsCount } from '../documents/store/documents.selector';
import { getTaxDocumentsForm, getTaxProfileForm } from '../form/store/form.selector';
import { goToProfile } from './store/profile.actions';
import { TaxDocumentsForm } from '../form/model/form.model';

@Component({
  selector: 'leaf-profile',
  template: `
    <div class="container">
      <h2>Bienvenue sur LEAF</h2>
      <div>
        <div class="info">
          <mat-list>
            <mat-list-item>
              <div mat-line>Compte: {{ (user$ | async)?.email }}</div>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="activities" *ngIf="{activity: activities$ | async, count: updatedDocumentsCount$ | async}">
          <leaf-activity *ngFor="let activity of (activities$ | async)"
                         [name]="activity.name"
                         status="{{ updatedDocumentsCount$ | async }} documents importés sur {{ activity.idList.length }} documents requis"
                         [percent]="100 / activity.idList.length * (updatedDocumentsCount$ | async)"></leaf-activity>
          <button mat-button color="primary" routerLink="/documents">Importer les documents</button>
        </div>
      </div>
      <div>
        <div>
          <div class="invitation">
            <h3>Parrainage</h3>
            <p><i>Prochainement: Invitez vos amis afin de réduire le prix de votre prochaine déclaration fiscale !</i></p>
          </div>
          <div class="payment">
            <h3>Vos souscriptions</h3>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Déclaration fiscale 2020
                </mat-panel-title>
                <mat-panel-description>
                  CHF {{ price$ | async }}.―
                </mat-panel-description>
              </mat-expansion-panel-header>
              <h4>Critères</h4>
              <ul>
                <li>{{ children }}</li>
                <li>{{ property }}</li>
                <li>{{ investments }}</li>
              </ul>
              <p>Service au prix de CHF {{ price$ | async }}.―</p>
<!--              <button mat-button color="primary">Consulter la facture</button>-->
            </mat-expansion-panel>
          </div>
        </div>
        <div class="info-form">
          <h3>Votre situation fiscale</h3>
          <leaf-overview [taxDocumentsForm]="taxDocumentsForm$ | async"></leaf-overview>
          <button mat-button color="primary" routerLink="/form">Modifier les données</button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    h3 {
      margin-top: 24px;
    }

    mat-list {
      margin-bottom: 24px;
    }

    mat-list-item {
      margin: 0 -16px;
    }

    ul {
      padding-inline-start: 24px;
    }

    .activities button, .info-form button {
      display: block;
      margin: 12px auto;
    }
  `]
})
export class ProfileComponent implements OnInit, OnDestroy {

  activities$: Observable<Activity[]>;
  price$: Observable<number>;
  updatedDocumentsCount$: Observable<number>;
  user$: Observable<User>;
  taxProfileSubscription: Subscription;
  taxDocumentsForm$: Observable<TaxDocumentsForm>;

  children: string;
  property: string;
  investments: string;

  constructor(
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.activities$ = this.store.pipe(select(getActivities));
    this.price$ = this.store.pipe(select(getPrice));
    this.updatedDocumentsCount$ = this.store.pipe(select(getImportedDocumentsCount));
    this.user$ = this.store.pipe(select(getAuthUser));
    this.taxProfileSubscription = this.store.pipe(
      select(getTaxProfileForm),
    ).subscribe(
      taxProfile => {
        if (taxProfile == null) {
          this.store.dispatch(goToProfile());
        } else {
          this.children = taxProfile.childrenInput ? 'Enfants à charge' : 'Aucun enfant à charge';
          this.property = taxProfile.propertyOwnerInput ? `${taxProfile.propertyNumberInput} bien(s) immobilier(s)` : 'Aucun bien immobilier';
          this.investments = taxProfile.investmentsInput ? taxProfile.investmentsInput
            .replace('Oui,', 'Titres ou placements')
            .replace('Non, je n\'ai pas', 'Pas') : '';
        }
      }
    );
    this.taxDocumentsForm$ = this.store.pipe(select(getTaxDocumentsForm));
  }

  ngOnDestroy() {
    this.taxProfileSubscription.unsubscribe();
  }
}
