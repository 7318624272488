import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private imageFileTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
  ];

  private maxSize = 24000000; // 24MB

  validateFile(file: File): boolean {
    return this.imageFileTypes.includes(file.type) && file.size <= this.maxSize;
  }
}
