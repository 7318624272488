import { Component, OnInit } from '@angular/core';
import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'leaf-footer',
  template: `
    <footer>
      <p class="love">Développé en Suisse avec
        <mat-icon>favorite</mat-icon>
        par LEAF Technology Sàrl
      </p>
      <p>Rue de l’Arquebuse 10, 1204 Genève<br/>© Tous droits réservés 2019-2021</p>
      <div class="social-network">
        <a href="https://ch.linkedin.com/company/leaf-swiss" target="_blank"><fa-icon [icon]="linkedin"></fa-icon></a>
        <a href="https://www.facebook.com/leaf.swiss/" target="_blank"><fa-icon [icon]="facebook"></fa-icon></a>
        <a href="https://www.instagram.com/leaf.swiss/" target="_blank"><fa-icon [icon]="instagram"></fa-icon></a>
        <a href="https://twitter.com/leaf_swiss" target="_blank"><fa-icon [icon]="twitter"></fa-icon></a>
        <a href="mailto:info@leaf.swiss" target="_blank"><fa-icon [icon]="at"></fa-icon></a>
      </div>
      <p><a routerLink="/terms">Conditions d'utilisation</a></p>
    </footer>
  `,
  styleUrls: ['footer.component.scss']
})
export class FooterComponent implements OnInit {

  facebook = faFacebook;
  linkedin = faLinkedin;
  instagram = faInstagram;
  twitter = faTwitter;
  at = faAt;

  constructor() { }

  ngOnInit(): void {
  }

}
