import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Validation } from './model/validation.model';

@Component({
  selector: 'leaf-validation',
  template: `
    <h3>{{ data.title }}</h3>
    <mat-dialog-content class="mat-typography">
      <p [innerHTML]="data.content | breakLines"></p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" (click)="close()">Annuler</button>
      <button mat-flat-button color="primary" (click)="valid()">Confirmer</button>
    </mat-dialog-actions>
  `,
  styles: [`
    mat-dialog-actions {
      display: flex;
      justify-content: flex-end;
    }
  `]
})
export class ValidationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Validation,
    public dialogRef: MatDialogRef<ValidationComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  valid() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
