import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getTaxProfileForm } from '../form/store/form.selector';
import { combineLatest, Observable } from 'rxjs';
import { State } from '../reducers';
import { getPrice } from './store/activation.selector';
import { Router } from '@angular/router';
import { goToProfile } from '../profile/store/profile.actions';
import { take, tap } from 'rxjs/operators';
import { getAuthUser } from '../authentication/store/authentication.selector';
import { TaxProfileForm } from '../form/model/form.model';
import { activationClicked } from './store/activation.actions';

@Component({
  selector: 'leaf-activation',
  template: `
    <header>
      <h1>Selon l’analyse de votre profil nous traitons votre déclaration au tarif de CHF {{ price$ | async }}.―</h1>
    </header>
    <div class="estimate primary-background">
      <h2>Vous avez déclaré</h2>
      <leaf-tax-profile [taxProfile]="taxProfile$ | async"></leaf-tax-profile>
      <h2>Votre offre peronnalisée au prix de CHF {{ price$ | async }}.―</h2>
      <button mat-flat-button (click)="startDeclaration('principal')">Démarrer ma déclaration</button>
    </div>
    <div class="motion-design">
      <leaf-motion-design></leaf-motion-design>
      <button mat-flat-button color="primary" (click)="startDeclaration('motion-design')">Démarrer ma déclaration</button>
    </div>
    <div class="light-background">
      <leaf-info-payment></leaf-info-payment>
    </div>
    <div class="why">
      <h2>Pourquoi utiliser LEAF pour optimiser votre déclaration d'impots ?</h2>
      <h3>Des experts métiers</h3>
      <p>Nous révolutionnons la fiscalité en Suisse en alliant la technologie et l’expertise de nos collaborateurs. En
        quelques clics seulement, nous nous occupons de tout et veillons à vous faire bénéficier de nos connaissances
        afin d’optimiser parfaitement votre déclaration d’impôt.
      </p>
      <h3>Une interface simple et un accompagnement humain</h3>
      <p>Avec LEAF, il n’a jamais été aussi facile de compléter sa déclaration d’impôt même si vous n’avez aucune
        compétence.<br/>Nos fiscalistes s’occupent de vos obligations fiscales en toute simplicité, de l’établissement
        jusqu’à l’envoi de votre déclaration fiscale aux administrations.<br/>Vous n’avez plus besoin de vous inquiéter
        et vous pouvez à tout moment retrouver vos informations en ligne.
      </p>
      <h3>Des économies d’impôts, un investissement qui rapporte</h3>
      <p>Notre maîtrise et nos compétences nous permettent d’identifier toutes les déductions possibles et d’optimiser
        votre déclaration d’impôt mieux que quiconque.<br/>Nos fiscalistes vous proposent une solution accessible qui
        vous fera économiser votre temps, du papier et de l’argent.<br/>En moyenne, nos clients ont fait une économie
        de plus de 10% d’impôts.
      </p>
      <button mat-flat-button (click)="startDeclaration('why')" color="primary">
        Démarrer ma déclaration pour CHF {{ price$ | async }}.―
      </button>
    </div>
    <div class="primary-background">
      <leaf-how light="true"></leaf-how>
    </div>
    <div class="three-errors">
      <h2>Les trois erreurs à éviter pour votre déclaration d’impots</h2>
      <h3>Faire une erreur sur la déclaration et oublier des déductions</h3>
      <p>Faites en sorte de déduire tout ce dont vous avez droit et d’optimiser votre déclaration d’impôt au maximum.
        Ainsi vous payerez le bon montant d’impôt et vous éviterez une mauvaise surprise.</p>
      <h3>De ne pas rendre à temps</h3>
      <p>Si vous ne déposez pas votre déclaration fiscale dans les temps, vous prenez le risque d’être pénalisé voire
        de vous faire taxer d’office.</p>
      <h3>Les mauvais acomptes</h3>
      <p>Si les montants des acomptes ne sont pas ajustés, vous risquez de payer trop ou pas assez d’acomptes. De plus,
        vous pourriez avoir des soucis de liquidités ainsi que des intérêts négatifs à payer ou de longues démarches
        administratives pour vous faire rembourser.</p>
    </div>
    <leaf-partners></leaf-partners>
    <div class="our-commitments">
      <h2>Nos engagements</h2>
      <div class="commitment">
        <h3>Qualification</h3>
        <p>Notre équipe de fiscalistes et d’ingénieurs a une forte expérience du marché Suisse, nous avons déjà optimisé
          plus de 5000 déclarations fiscales.</p>
      </div>
      <div class="commitment">
        <h3>Swiss Made</h3>
        <p>Notre service est basé à Genève et à Lausanne, vos données sont sécurisées et chiffrées en Suisse.</p>
      </div>
      <div class="commitment">
        <h3>Écologique</h3>
        <p>LEAF c’est aussi une solution moderne au traitement du papier, de la gestion administrative et au respect
          environnementale.</p>
      </div>
      <button mat-flat-button (click)="startDeclaration('our-commitment')" color="primary">
        Démarrer ma déclaration pour CHF {{ price$ | async }}.―
      </button>
    </div>
    <leaf-testimonial></leaf-testimonial>
    <div class="target">
      <h2>A qui s’adresse TAX by Leaf ?</h2>
      <ul>
        <li>
          <mat-icon>check</mat-icon>
          <span>Vous payez trop d’impôt</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Vous avez envie d’optimiser vos impôts</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Votre temps est précieux et vous préférez déléguer</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Vous n’aimez pas la fiscalité et n’êtes pas à l’aise avec les tâches administratives</span>
        </li>
      </ul>
    </div>
    <div class="money-back primary-background">
      <ul>
        <li>
          <mat-icon>check</mat-icon>
          <span>Fiscalistes qualifiés</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Déclarations remplies et envoyées dans les temps</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Prix accessibles et rentables</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Déclaration optimisée au maximum</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Nos clients économisent 10% en moyenne</span></li>
        <li>
          <mat-icon>check</mat-icon>
          <span>Satisfait ou remboursé</span></li>
      </ul>
    </div>
    <leaf-faq></leaf-faq>
    <button mat-flat-button (click)="startDeclaration('last')" color="primary">
      Démarrer ma déclaration pour CHF {{ price$ | async }}.―
    </button>
    <leaf-info-payment></leaf-info-payment>
    <leaf-footer></leaf-footer>
  `,
  styleUrls: ['activation.component.scss']
})
export class ActivationComponent implements OnInit {


  price$: Observable<number>;
  taxProfile$: Observable<TaxProfileForm>;

  constructor(
    private store: Store<State>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.price$ = this.store.pipe(
      select(getPrice),
      tap(price => {
        if (price === null) {
          this.router.navigate(['/']);
        }
      })
    );
    this.taxProfile$ = this.store.pipe(select(getTaxProfileForm));
  }

  startDeclaration(buttonId: string) {
    combineLatest([
      this.store.pipe(select(getAuthUser)),
      this.store.pipe(select(getPrice))
    ]).pipe(
      take(1)
    ).subscribe(([user, price]) => {
      if (user) {
        this.store.dispatch(goToProfile());
      }
      else {
        this.store.dispatch(activationClicked({price, buttonId}));
        this.router.navigate(['/signup']);
      }
    });
  }
}
