import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { FormGroup } from '@angular/forms';
import { Question } from '../../model/form.model';
import formOptions from './../../model/tax-documents-options.constant';

@Component({
  selector: 'leaf-checklist-simple',
  template: `
    <div [formGroup]="parentForm">
      <h3>{{question.title}}</h3>
      <span *ngIf="question.hint" class="hint">{{ question.hint }}</span>
      <mat-selection-list
        [formControlName]="question.controlName"
        (selectionChange)="listChanged($event)"
        color="primary"
      >
        <mat-list-option *ngFor="let item of values" [value]="item" checkboxPosition="before">
          {{ formOptions[item] }}
        </mat-list-option>
        <mat-list-option *ngIf="enableOthers"
                         (focusin)="focusOnInput()"
                         checkboxPosition="before">
          <input matInput #other
                 type="text"
                 placeholder="Autres...">
        </mat-list-option>
      </mat-selection-list>
    </div>
  `,
  styles: [`
    mat-selection-list {
      padding-top: 0;
      padding-bottom: 6px;
    }

    mat-list-option {
      margin-left: -16px;
    }

    mat-list-option {
      font-size: 14px !important;
    }

    .mat-list-item-disabled {
      background: none;
      opacity: 0.5;
      outline: none;
    }
  `],
  styleUrls: ['./../common/common.question.scss']
})
export class ChecklistSimpleComponent {

  @Input() question: Question;
  @Input() parentForm: FormGroup;
  @Input() values: string[];
  @Input() enableOthers: boolean;
  @Output() listChange = new EventEmitter<any>();
  @ViewChild('other') inputOther: ElementRef;

  formOptions = formOptions;

  constructor() {
  }

  focusOnInput() {
    this.inputOther.nativeElement.focus();
  }

  listChanged(assets: MatSelectionListChange) {
    const values = this.parentForm.controls[this.question.controlName].value as Array<string>;
    if (values.length > 0 && values[values.length - 1] === undefined) {
      values[values.length - 1] = this.inputOther.nativeElement.value;
    }

    const newAssets = assets.source.selectedOptions.selected.map(
      (option) => option.value
    );
    this.listChange.emit(newAssets);
  }
}
