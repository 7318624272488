import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(array: any[]): string {
    return array?.join(', ');
  }
}

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {

  transform(array: any[]): boolean {
    return array?.length === 0;
  }
}

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(array: any[], item: any): boolean {
    return array?.includes(item);
  }
}
