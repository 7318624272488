import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import ProfileState from './profile.reducer';

export const getProfileState = (state: State) => state.profile;

export const getProfileSelector = createSelector(
  getProfileState,
  (state: ProfileState) => state.profile
);

export const getPaidSelector = createSelector(
  getProfileState,
  (state: ProfileState) => state.profile ? state.profile.paid : false
);

export const getTaxValidatedSelector = createSelector(
  getProfileState,
  (state: ProfileState) => state.profile ? state.profile.taxValidated : false
);


export const isAdminSelector = createSelector(
  getProfileState,
  (state: ProfileState) => state.profile ? state.profile.admin : false
);
