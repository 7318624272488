import { createAction, props } from '@ngrx/store';
import { TaxFormSummary } from '../model/tax-form-summary.model';
import { User } from '../../authentication/model/user.model';

export const getUserStatuses = createAction(
  '[Admin] get user statuses'
);

export const getUserStatusesSuccess = createAction(
  '[Admin] get user statuses success',
  props<{ statuses: TaxFormSummary[] }>()
);

export const getUserStatusesFailed = createAction(
  '[Admin] get user statuses failure',
  props<{ error: Error }>()
);

export const getSelectedUser = createAction(
  '[Admin] get selected user',
  props<{ userId: string }>()
);

export const getSelectedUserSuccess = createAction(
  '[Admin] get selected user success',
  props<{ user: User }>()
);

export const getSelectedUserFailed = createAction(
  '[Admin] get selected user failure',
  props<{ error: Error }>()
);
