import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { subscribeToNewsletter } from './newsletter.actions';
import { MailChimpService } from '../service/mailchimp.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterEffects {

  constructor(
    private actions$: Actions,
    private mailChimpService: MailChimpService,
  ) {
  }

  subscribeToNewsletterEffect$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeToNewsletter.type),
    tap((action: ReturnType<typeof subscribeToNewsletter>) => this.mailChimpService.save(action.email))
  ), {dispatch: false});

}
