import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { createToken, paymentConfirmed, paymentFailed, tokenCreated, tokenCreationFailed } from './payment.actions';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PaymentService } from '../service/payment.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getAuthUser } from '../../authentication/store/authentication.selector';
import { Payment } from '../model/payment.model';
import { getPrice } from '../../activation/store/activation.selector';
import { User } from '../../authentication/model/user.model';
import { refreshProfileAfterPayment } from '../../datastore/store/datastore.actions';
import { TokenResult } from '@nomadreservations/ngx-stripe';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentEffects {

  constructor(
    private actions$: Actions,
    private payService: PaymentService,
    private router: Router,
    private store: Store,
  ) {
  }

  createTokenEffect$ = createEffect(() => this.actions$.pipe(
    ofType(createToken.type),
    mergeMap((action: ReturnType<typeof createToken>) => this.payService.createToken(action.element, environment.appName).pipe(
      map((token: TokenResult) => {
        if (token.error) {
          return tokenCreationFailed({error: Error(token.error.message)});
        } else {
          return tokenCreated({token});
        }
      }),
      catchError(error => of(tokenCreationFailed({error})))
    ))
  ));

  sendPaymentEffect$ = createEffect(() => this.actions$.pipe(
    ofType(tokenCreated.type),
    withLatestFrom(
      this.store.pipe(select(getAuthUser)),
      this.store.pipe(select(getPrice))
    ),
    mergeMap(([action, user, price]: [ReturnType<typeof tokenCreated>, User, number]) => this.payService.sendPayment({
      email: user.email,
      amount: price,
      token: action.token.token.id
    } as Payment).pipe(
      map(() => paymentConfirmed({transactionId: action.token.token.id, price})),
      catchError(error => of(paymentFailed({error})))
    )),
  ));

  paymentConfirmedEffect$ = createEffect(() => this.actions$.pipe(
    ofType(paymentConfirmed.type),
    delay(2000),
    map(() => refreshProfileAfterPayment())
  ));

}
