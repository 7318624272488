import { Injectable } from '@angular/core';
import { TaxDocumentsForm, } from '../../form/model/form.model';
import { Document, Documents } from '../model/documents.model';
import taxDocuments from '../model/tax-documents.constant';
import documentList from '../model/documents.constant';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor() {
  }

  listDocuments(taxDocumentsForm: TaxDocumentsForm): Documents {
    const defaultList: Document[] = taxDocuments.default.map(documentId => this.findDocument(documentId));

    const list: Document[] = Object.keys(taxDocumentsForm).reduce((acc: Document[], controlName: string) => {
      if (this.isYesOrNoAnswer(taxDocumentsForm[controlName]) && taxDocumentsForm[controlName]) {
        taxDocuments[controlName].forEach(documentId => acc.push(this.findDocument(documentId)));
      }
      if (this.isChecklistAnswers(taxDocumentsForm[controlName])) {
        taxDocumentsForm[controlName].forEach(answerId => {
          taxDocuments[controlName][answerId].forEach(documentId => acc.push(this.findDocument(documentId)));
        });
      }
      return acc;
    }, defaultList);
    return new Documents('Déclaration fiscale 2020', list);
  }

  findDocument(documentId): Document {
    return new Document(documentList.find(document => document.id === documentId));
  }

  isYesOrNoAnswer(answer: any): boolean {
    return typeof answer === 'boolean';
  }

  isChecklistAnswers(answers: any): boolean {
    return answers && Array.isArray(answers);
  }
}
