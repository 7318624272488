import { environment } from '../environments/environment';
import { APP_NAME, CONFIG, DEBUG_MODE } from '@angular/fire/analytics';

export const CONFIG_PROVIDER = {
  provide: CONFIG, useValue:
    {
      send_page_view: environment.enableAnalytics,
      allow_ad_personalization_signals: false,
      anonymize_ip: true
    }
};

export const DEBUG_MODE_PROVIDER = {
  provide: DEBUG_MODE, useValue: !environment.production
};

export const APP_NAME_PROVIDER = {
  provide: APP_NAME, useValue: environment.appName
};

export const COLLECTION_ENABLED_PROVIDER = {
  provide: APP_NAME, useValue: environment.enableAnalytics
};
