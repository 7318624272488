import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Questions from './../model/tax-profile-form.constant';
import { MatStepper } from '@angular/material/stepper';
import {
  changeResidency,
  changeNationality,
  changeChildren,
  changePropertyOwner,
  changeInvestments,
  submitTaxProfile,
  startTaxProfile, changePropertyNumber
} from '../store/form.actions';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { TaxProfileForm } from '../model/form.model';
import { Subscription } from 'rxjs';
import { getTaxProfileForm } from '../store/form.selector';
import { MatDialog } from '@angular/material/dialog';
import { UnavailableServiceComponent } from '../../unavailable-service/unavailable-service.component';

@Component({
  selector: 'leaf-tax-profile-form',
  templateUrl: 'tax-profile-form.component.html',
  styleUrls: ['./../form.component.scss']
})
export class TaxProfileFormComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private myStepper: MatStepper;

  questions = Questions;
  showPropertyNumberQuestion = false;

  residencyFormGroup: FormGroup;
  nationalityFormGroup: FormGroup;
  childrenFormGroup: FormGroup;
  propertyOwnerFormGroup: FormGroup;
  investmentsFormGroup: FormGroup;

  existingTaxProfileSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) {
    this.residencyFormGroup = this.formBuilder.group({
      residency: ['', [Validators.required, this.inCoverageAreaValidator.bind(this)]],
    });
    this.nationalityFormGroup = this.formBuilder.group({
      nationality: [null, [Validators.required, this.swissOrCPermitValidator.bind(this)]],
    });
    this.childrenFormGroup = this.formBuilder.group({
      children: [null, Validators.required],
    });
    this.propertyOwnerFormGroup = this.formBuilder.group({
      propertyOwner: ['', Validators.required],
      propertyNumber: [0, Validators.required],
    });
    this.investmentsFormGroup = this.formBuilder.group({
      investments: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.store.dispatch(startTaxProfile());
    this.existingTaxProfileSubscription = this.store.pipe(
      select(getTaxProfileForm),
    ).subscribe(
      taxProfile => {
        if (taxProfile) {
          this.residencyFormGroup.controls.residency.setValue(taxProfile.residencyInput);
          this.nationalityFormGroup.controls.nationality.setValue(taxProfile.nationalityInput);
          this.childrenFormGroup.controls.children.setValue(taxProfile.childrenInput);
          this.propertyOwnerFormGroup.controls.propertyOwner.setValue(taxProfile.propertyOwnerInput);
          this.propertyOwnerFormGroup.controls.propertyNumber.setValue(taxProfile.propertyNumberInput);
          this.investmentsFormGroup.controls.investments.setValue(taxProfile.investmentsInput);
          if (taxProfile.propertyOwnerInput) {
            this.propertyOwnerFormGroup.controls.propertyNumber.setValidators([
              Validators.required,
              Validators.min(1)
            ]);
            this.showPropertyNumberQuestion = true;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.existingTaxProfileSubscription.unsubscribe();
  }

  residencyChanged(residency: string) {
    this.store.dispatch(changeResidency({residency}));
    if (this.inCoverageArea(residency)) {
      this.myStepper.next();
    } else {
      this.dialog.open(UnavailableServiceComponent, {
        maxWidth: 512,
        data: {
          titre: 'Notre service n\'est pas encore accessible dans votre canton',
          content: 'Vous pouvez nous transmettre votre adresse e-mail afin d\'être informé dès que votre canton sera pris en compte.'
        }
      });
    }
  }

  private inCoverageAreaValidator(residencyControl: AbstractControl): { [key: string]: boolean } | null {
    if (residencyControl.value && this.inCoverageArea(residencyControl.value)) {
      return;
    }
    return { residency: false } ;
  }

  private inCoverageArea(residency: string): boolean {
    return residency !== Questions.residencyValues[Questions.residencyValues.length - 1];
  }

  nationalityChanged(nationality: boolean) {
    this.store.dispatch(changeNationality({nationality}));
    if (nationality) {
      this.myStepper.next();
    } else {
      this.dialog.open(UnavailableServiceComponent, {
        maxWidth: 512,
        data: {
          titre: 'Vous n\'êtes pas soumis à la déclaration d\'impôts',
          content: 'En tant que travailleur étranger non titulaires d’un permis C (autorisation d’établissement) ' +
              'vous êtes soumis à l’impôt à la source. Ce dernier est prélevé de votre salaire directement par votre employeur.\n' +
              'Vous pouvez nous transmettre votre adresse e-mail afin d\'être informé des nouvelles fonctionnalités de notre service.'
        }
      });
    }
  }

  private swissOrCPermitValidator(nationalityControl: AbstractControl): { [key: string]: boolean } | null {
    if (nationalityControl.value) {
      return;
    }
    return { nationality: false } ;
  }

  childrenChanged(children: boolean) {
    this.store.dispatch(changeChildren({children}));
    this.myStepper.next();
  }

  propertyOwnerChanged(propertyOwner: boolean) {
    this.store.dispatch(changePropertyOwner({propertyOwner}));
    if (!propertyOwner) {
      this.propertyOwnerFormGroup.controls.propertyNumber.setValidators(Validators.required);
      this.propertyOwnerFormGroup.controls.propertyNumber.updateValueAndValidity();
      this.showPropertyNumberQuestion = false;
      this.myStepper.next();
    } else {
      this.propertyOwnerFormGroup.controls.propertyNumber.setValidators([
        Validators.required,
        Validators.min(1)
      ]);
      this.propertyOwnerFormGroup.controls.propertyNumber.updateValueAndValidity();
      this.showPropertyNumberQuestion = true;
    }
  }

  propertyNumberChanged(propertyNumber: number) {
    this.store.dispatch(changePropertyNumber({propertyNumber}));
  }

  investmentsChanged(investments: string) {
    this.store.dispatch(changeInvestments({investments}));
    this.formSubmit();
  }

  formSubmit() {
    const taxProfileForm = {
      residencyInput: this.residencyFormGroup.controls.residency.value,
      nationalityInput: this.nationalityFormGroup.controls.nationality.value,
      childrenInput: this.childrenFormGroup.controls.children.value,
      propertyOwnerInput: this.propertyOwnerFormGroup.controls.propertyOwner.value,
      propertyNumberInput: this.propertyOwnerFormGroup.controls.propertyOwner.value ?
        this.propertyOwnerFormGroup.controls.propertyNumber.value : 0,
      investmentsInput: this.investmentsFormGroup.controls.investments.value,
    } as TaxProfileForm;
    this.store.dispatch(submitTaxProfile({taxProfileForm}));
  }
}
