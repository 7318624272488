import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './service/authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }

  canActivate(): Observable<boolean> | boolean {
    return this.checkLogin();
  }

  checkLogin(): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      map(connected => {
        if (connected) {
          return true;
        }
        this.router.navigate(['/signup'], { replaceUrl: true });
        return false;
      })
    );
  }
}
