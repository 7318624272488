import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import DocumentsState from './documents.reducer';

export const getDocumentsState = (state: State) => state.documents;

export const getDocuments = createSelector(
  getDocumentsState,
  (state: DocumentsState) => state.documents
);

export const getActivities = createSelector(
  getDocumentsState,
  (state: DocumentsState) => state.activities
);

export const getImportedDocumentsCount = createSelector(
  getDocumentsState,
  (state: DocumentsState) => state.documents ? state.documents.filter(document => typeof document.status === 'number').length : 0
);
