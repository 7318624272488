import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import PaymentState from './payment.reducer';

export const getPaymentState = (state: State) => state.payment;

export const getCurrentPaymentState = createSelector(
  getPaymentState,
  (state: PaymentState) => state.currentState
);
