import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import AdminState from './admin.reducer';

export const getAdminState = (state: State) => state.admin;

export const getUserStatusSelector = createSelector(
  getAdminState,
  (state: AdminState) => state.userStatuses
);

export const getSelectedUserSelector = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedUser
);
