import { createAction, props } from '@ngrx/store';
import { Activity, Document, ImportedDoc, Observation } from '../model/documents.model';

export const documentListIdentified = createAction(
  '[Documents] Document list identified',
  props<{ documents: Document[], activities: Activity[] }>()
);

export const importedDocumentsLinked = createAction(
  '[Documents] imported documents linked',
  props<{ documents: Document[] }>()
);

export const observationsLinked = createAction(
  '[Documents] observations linked',
  props<{ documents: Document[] }>()
);

export const uploadingFileToDocument = createAction(
  '[Documents] Uploading file to document',
  props<{ documentId: string }>()
);

export const uploadingFileToDocumentSuccess = createAction(
  '[Documents] Uploading file to document success',
  props<{ importedDoc: ImportedDoc }>()
);

export const uploadingFileToDocumentFailure = createAction(
  '[Documents] Uploading file to document failure',
  props<{ documentId: string }>()
);

export const taxValidated = createAction(
  '[Documents] Tax validated'
);

export const observationChanged = createAction(
  '[Documents] Observation changed',
  props<{ observation: Observation }>()
);

export const observationChangedSuccess = createAction(
  '[Documents] Observation changed success',
  props<{ documentId: string }>()
);


