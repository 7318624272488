<div>
  <mat-horizontal-stepper linear #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step [stepControl]="residencyFormGroup" editable>
      <form [formGroup]="residencyFormGroup">
        <ng-template matStepLabel>Canton</ng-template>
        <leaf-choice-simple [question]="questions.residencyQuestion"
                            [values]="questions.residencyValues"
                            [parentForm]="residencyFormGroup"
                            (choiceChange)="residencyChanged($event)"
        ></leaf-choice-simple>
        <button *ngIf="residencyFormGroup.controls.residency.valid"
                mat-flat-button
                color="primary"
                type="button"
                matStepperNext>Suivant</button>
      </form>
    </mat-step>
    <mat-step [stepControl]="nationalityFormGroup" editable>
      <form [formGroup]="nationalityFormGroup">
        <ng-template matStepLabel>Nationalité</ng-template>
        <leaf-yes-or-no-simple [question]="questions.nationalityQuestion"
                               [value]=""
                               [parentForm]="nationalityFormGroup"
                               (valueChange)="nationalityChanged($event)"
        ></leaf-yes-or-no-simple>
        <button mat-stroked-button matStepperPrevious>Précédent</button>
        <button *ngIf="nationalityFormGroup.controls.nationality.valid"
                mat-flat-button
                color="primary"
                type="button"
                matStepperNext>Suivant</button>
      </form>
    </mat-step>
    <mat-step [stepControl]="childrenFormGroup" editable>
      <form [formGroup]="childrenFormGroup">
        <ng-template matStepLabel>Famille</ng-template>
        <leaf-yes-or-no-simple [question]="questions.childrenQuestion"
                               [value]=""
                               [parentForm]="childrenFormGroup"
                               (valueChange)="childrenChanged($event)"
        ></leaf-yes-or-no-simple>
        <button mat-stroked-button matStepperPrevious>Précédent</button>
        <button *ngIf="childrenFormGroup.controls.children.valid"
                mat-flat-button
                color="primary"
                type="button"
                matStepperNext>Suivant</button>
      </form>
    </mat-step>
    <mat-step [stepControl]="propertyOwnerFormGroup" editable>
      <form [formGroup]="propertyOwnerFormGroup">
        <ng-template matStepLabel>Immobilier</ng-template>
        <leaf-yes-or-no-simple [question]="questions.propertyOwnerQuestion"
                               [value]=""
                               [parentForm]="propertyOwnerFormGroup"
                               (valueChange)="propertyOwnerChanged($event)"
        ></leaf-yes-or-no-simple>
        <leaf-amount-simple *ngIf="showPropertyNumberQuestion"
                            [question]="questions.propertyNumberQuestion"
                            [parentForm]="propertyOwnerFormGroup"
                            (amountChanged)="propertyNumberChanged($event)"
        ></leaf-amount-simple>
        <button mat-stroked-button matStepperPrevious>Précédent</button>
        <button *ngIf="propertyOwnerFormGroup.valid"
                mat-flat-button
                color="primary"
                type="button"
                matStepperNext>Suivant</button>
      </form>
    </mat-step>
    <mat-step [stepControl]="investmentsFormGroup" editable>
      <form [formGroup]="investmentsFormGroup">
        <ng-template matStepLabel>Titres</ng-template>
        <leaf-choice-simple [question]="questions.investmentsQuestion"
                            [values]="questions.investmentsValues"
                            [parentForm]="investmentsFormGroup"
                            (choiceChange)="investmentsChanged($event)"
        ></leaf-choice-simple>
        <button mat-stroked-button matStepperPrevious>Précédent</button>
        <button *ngIf="investmentsFormGroup.controls.investments.valid"
                mat-flat-button
                color="primary"
                type="button"
                (click)="formSubmit()">Suivant</button>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
