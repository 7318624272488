import { Component, Input } from '@angular/core';
import { TaxFormSummary } from './model/tax-form-summary.model';

@Component({
  selector: 'leaf-admin-user-summary',
  template: `
    <div class="user">
      <h4>{{taxFormSummary.email}} - {{taxFormSummary.userId}}</h4>
      <div>Has validated tax: {{taxFormSummary.taxValidated ? 'yes' : 'no'}}</div>
      <div *ngIf="taxFormSummary.taxValidationDate as validationDate">Validation date: {{validationDate | timestampToString}} </div>
<!--      <div *ngIf="taxFormSummary.importedDocuments.length">-->
<!--        <h5>Imported documents:</h5>-->
<!--        <ul>-->
<!--          <li *ngFor="let doc of taxFormSummary.importedDocuments">-->
<!--            <a [href]="doc.url" target="_blank">{{doc.name}}</a> ({{doc.kind}})-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
      <a [routerLink]="['documents/', taxFormSummary.userId]">Accéder aux documents de l'utilisateur</a>
    </div>
  `,
  styles: [`
    .user {
      margin: 8px;
      background-color: lightgrey;
      padding: 8px;
      border: solid darkgray;
      border-radius: 2px;
    }

    .user h4 {
      margin-top: 0;
    }
  `]
})
export class UserSummaryComponent {

  @Input() taxFormSummary: TaxFormSummary;

  constructor() {
  }

}
