import { NgModule } from '@angular/core';
import { ActivationComponent } from './activation.component';
import { ContentModule } from '../content/content.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ProfileModule } from '../profile/profile.module';
import { FormModule } from '../form/form.module';

@NgModule({
  imports: [
    ContentModule,
    MatButtonModule,
    RouterModule,
    CommonModule,
    MatIconModule,
    ProfileModule,
    FormModule,
  ],
  declarations: [
    ActivationComponent,
  ]
})
export class ActivationModule { }
