import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { missingDocumentClick, signUp } from '../store/authentication.actions';
import { getError } from '../store/authentication.selector';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'leaf-signup',
  template: `
    <form>
      <h3>Créer un compte</h3>
      <p>Créez avec nous l\'économie de demain</p>
      <p *ngIf="messageError" class="error-msg">{{ messageError }}</p>
      <mat-form-field [formGroup]="signUpForm">
        <mat-label>Adresse e-mail</mat-label>
        <input matInput type="email"
               formControlName="email">
      </mat-form-field>
      <mat-form-field [formGroup]="signUpForm">
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password"
               formControlName="password">
        <mat-error *ngIf="signUpForm.get('password').errors">Le mot de passe doit contenir au minimum 8 caractères
        </mat-error>
      </mat-form-field>
      <button mat-flat-button
              color="primary"
              type="submit"
              (click)="formSubmit()"
              [disabled]="signUpForm.invalid">CRÉER UN COMPTE
      </button>
      <p>En cliquant sur <strong>créer un compte</strong>,
        vous confirmez accepter les <a routerLink="/terms">conditions d'utilisation</a>
        ainsi que notre politique de confidentialité</p>
      <mat-divider></mat-divider>
      <p>Vous avez déjà un compte ?</p>
      <a routerLink="/signin">Se connecter</a>
    </form>
  `,
  styleUrls: ['./../common/authentication.common.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  signUpForm: FormGroup;
  messageError: string;
  errorSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.errorSubscription = this.store.pipe(select(getError)).subscribe(error => {
      if (error) {
        this.messageError = this.getMessageError(error);
      } else {
        this.messageError = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
  }

  formSubmit() {
    this.store.dispatch(signUp({
      email: this.signUpForm.value.email,
      password: this.signUpForm.value.password
    }));
  }

  showMissingDocumentSnackBar() {
    this.store.dispatch(missingDocumentClick());
    this.snackBar.open('Le document demandé est momentanément indisponible. Veuillez nous contacter (info@leaf.swiss)',
      null,
      {
        duration: 3000
      });
  }

  getMessageError(error: any) {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'L\'adresse mail est déjà utilisée sur la plateforme';
      case 'auth/invalid-email':
        return 'L\'adresse mail n\'est pas valide';
      case 'auth/weak-password':
        return 'Le mot de passe n\'est pas suffisamment robuste';
      default:
        console.error(error);
    }
    return error.message;
  }
}
