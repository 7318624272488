import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[leafDragAndDrop]'
})
export class DragAndDropDirective {
  @HostBinding('class.files-over') filesOver: boolean;
  @Output() filesDropped = new EventEmitter<File[]>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.filesOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.filesOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.filesOver = false;
    const files = Array.from(evt.dataTransfer.files) as File[];
    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
