import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { getAuthUser } from '../store/authentication.selector';
import { getUser, signOut } from '../store/authentication.actions';
import { goToProfile } from '../../profile/store/profile.actions';

@Component({
  selector: 'leaf-top-bar',
  template: `
    <mat-toolbar color="primary">
      <img routerLink="/" src="assets/img/logo-horizontal.png" alt="LEAF Technology"/>
      <span class="fill-remaining-space"></span>
      <span  *ngIf="user$ | async">
        <mat-icon class="menu-icon" color="primary" aria-hidden="false" aria-label="Menu" [matMenuTriggerFor]="menu">menu</mat-icon>
        <mat-menu #menu="matMenu" yPosition="below">
          <button mat-menu-item disabled>
            <span>{{ (user$ | async)?.email }}</span>
          </button>
          <button mat-menu-item (click)="goToDashBoard()">
            <span>Dashboard</span>
          </button>
          <button mat-menu-item (click)="signOut()">
            <span>Déconnexion</span>
          </button>
        </mat-menu>
      </span>
      <span  *ngIf="(user$ | async) == null">
        <button mat-button routerLink="/signin">Connexion</button>
      </span>
    </mat-toolbar>
  `,
  styleUrls: ['top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {

  user$: Observable<User>;

  constructor(
    private store: Store<State>,
  ) {
    this.user$ = this.store.pipe(select(getAuthUser));
  }

  ngOnInit(): void {
    this.store.dispatch(getUser());
  }

  goToDashBoard() {
    this.store.dispatch(goToProfile());
  }

  signOut() {
    this.store.dispatch(signOut());
  }
}
