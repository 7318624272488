import { createAction, props } from '@ngrx/store';
import {
  Element as StripeElement, SourceResult, TokenResult
} from '@nomadreservations/ngx-stripe';
import { Payment } from '../model/payment.model';

export const createToken = createAction(
  '[Payment] create token',
  props<{ element: StripeElement }>()
);

export const tokenCreated = createAction(
  '[Payment] token created',
  props<{ token: TokenResult }>()
);

export const tokenCreationFailed = createAction(
  '[Payment] token creation failed',
  props<{ error: Error }>()
);

export const sendPayment = createAction(
  '[Payment] send payment',
  props<{ payment: Payment }>()
);

export const paymentConfirmed = createAction(
  '[Payment] payment confirmed',
  props<{ transactionId: string, price: number }>()
);

export const paymentFailed = createAction(
  '[Payment] payment failed',
  props<{ error: Error }>()
);
