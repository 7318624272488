import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { forgotPassword } from '../store/authentication.actions';
import { Observable, Subscription } from 'rxjs';
import { getError, getResetPasswordSent } from '../store/authentication.selector';

@Component({
  selector: 'leaf-signin',
  template: `
    <form>
      <h3>Récupération du mot de passe</h3>
      <p *ngIf="resetPasswordSent$ | async"
         class="confirm-msg">Un lien pour la réinitialisation du mot de passe a été envoyé à votre adresse mail</p>
      <p *ngIf="messageError" class="error-msg">{{ messageError }}</p>
      <mat-form-field [formGroup]="forgotPasswordForm">
        <mat-label>Adresse e-mail</mat-label>
        <input matInput type="email"
               formControlName="email">
      </mat-form-field>
      <button mat-flat-button
              color="primary"
              type="submit"
              (click)="formSubmit()"
              [disabled]="forgotPasswordForm.invalid || (resetPasswordSent$ | async)">RÉINITIALISER LE MOT DE PASSE
      </button>
      <mat-divider></mat-divider>
      <p>Vous n'avez pas encore de compte ?</p>
      <a routerLink="/signup" queryParamsHandling="preserve">Créer un compte</a>
    </form>
  `,
  styleUrls: ['./../common/authentication.common.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;
  messageError: string;
  errorSubscription: Subscription;
  resetPasswordSent$: Observable<any>;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.errorSubscription = this.store.pipe(select(getError)).subscribe(error => {
      if (error) {
        this.messageError = this.getMessageError(error);
      } else {
        this.messageError = null;
      }
    });
    this.resetPasswordSent$ = this.store.pipe(select(getResetPasswordSent));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
  }

  formSubmit() {
    this.store.dispatch(forgotPassword({
      email: this.forgotPasswordForm.value.email
    }));
  }

  getMessageError(error: any) {
    switch (error.code) {
      case 'auth/invalid-email':
        return 'L\'adresse mail n\'est pas valide';
      default:
        console.error(error);
    }
    return error.message;
  }
}
