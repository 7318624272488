import { createAction, props } from '@ngrx/store';
import { TaxDocumentsForm, TaxProfileForm } from '../../form/model/form.model';
import { Profile } from '../../profile/model/profile.model';

export const saveTaxProfileData = createAction(
  '[Datastore] save tax profile data',
  props<{ taxProfileForm: TaxProfileForm }>()
);

export const saveTaxProfileDataSuccess = createAction(
  '[Datastore] save tax profile data success'
);

export const getTaxProfileData = createAction(
  '[Datastore] get tax profile data'
);

export const getTaxProfileDataSuccess = createAction(
  '[Datastore] get tax profile data success',
  props<{ taxProfileForm: TaxProfileForm }>()
);

export const getTaxProfileDataFailure = createAction(
  '[Datastore] get tax profile data failure'
);

export const saveTaxDocumentsData = createAction(
  '[Datastore] save tax documents data',
  props<{ taxDocumentsForm: TaxDocumentsForm }>()
);

export const saveTaxDocumentsDataSuccess = createAction(
  '[Datastore] save tax documents data success'
);

export const getTaxDocumentsData = createAction(
  '[Datastore] get tax documents data'
);

export const getTaxDocumentsDataSuccess = createAction(
  '[Datastore] get tax documents data success',
  props<{ taxDocumentsForm: TaxDocumentsForm }>()
);

export const getTaxDocumentsDataFailure = createAction(
  '[Datastore] get tax documents data failure'
);

export const getProfile = createAction(
  '[Datastore] get profile'
);

export const getProfileSuccess = createAction(
  '[Datastore] get profile success',
  props<{ profile: Profile }>()
);

export const refreshProfileAfterPayment = createAction(
  '[Datastore] refresh profile after payment'
);

export const refreshProfileSuccess = createAction(
  '[Datastore] refresh profile success',
  props<{ profile: Profile }>()
);
