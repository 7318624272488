import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import ActivationState from './activation.reducer';

export const getActivationState = (state: State) => state.activation;

export const getPrice = createSelector(
  getActivationState,
  (state: ActivationState) => state.price
);
