import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Document, DocumentFile, Documents, ImportedDoc, Observation } from '../model/documents.model';
import { documentListIdentified, importedDocumentsLinked, observationsLinked } from './documents.actions';
import { DocumentsService } from '../service/documents.service';
import { getTaxDocumentsDataSuccess } from '../../datastore/store/datastore.actions';
import { submitTaxDocumentsForm } from '../../form/store/form.actions';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { DatastoreService } from '../../datastore/service/datastore.service';
import { getAuthUser } from '../../authentication/store/authentication.selector';
import { User } from '../../authentication/model/user.model';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
  providedIn: 'root'
})
export class DocumentsEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>,
    private datastoreService: DatastoreService,
    private documentsService: DocumentsService,
  ) {
  }

  identifyDocumentsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(submitTaxDocumentsForm.type, getTaxDocumentsDataSuccess.type),
    map((action: ReturnType<typeof submitTaxDocumentsForm> | ReturnType<typeof getTaxDocumentsDataSuccess>): Documents =>
      this.documentsService.listDocuments(action.taxDocumentsForm)
    ),
    map((documentsFinalized: Documents) =>
      documentListIdentified({documents: documentsFinalized.list, activities: documentsFinalized.activities})
    )
  ));

  linkImportedDocumentsDocumentsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(documentListIdentified.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([action, user]: [ReturnType<typeof documentListIdentified>, User]) =>
      this.datastoreService.loadImportedDocData(user).pipe(
        map((importedDocs: ImportedDoc[]): Document[] => {
          importedDocs.sort((a, b) => (b.lastChange as Timestamp).seconds - (a.lastChange as Timestamp).seconds);
          return action.documents.map(document => {
              const importedDocsForCurrentDocument = importedDocs.filter(file => file.documentId === document.id);
              if (importedDocsForCurrentDocument.length) {
                return {...document,
                  status: importedDocsForCurrentDocument.length,
                  lastChange: (importedDocsForCurrentDocument[0].lastChange as Timestamp).toDate(),
                  files: importedDocsForCurrentDocument.map(importedDoc => importedDoc as DocumentFile)
                };
              }
              return document;
            });
        }),
        map((documentsFinalized: Document[]) =>
          importedDocumentsLinked({documents: documentsFinalized})
        )
      )
    )
  ));

  submitTaxDocumentsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(importedDocumentsLinked.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([action, user]: [ReturnType<typeof importedDocumentsLinked>, User]) =>
      this.datastoreService.loadObservationData(user).pipe(
        map((observations: Observation[]): Document[] => {
          return action.documents.map(document => {
              const observationForCurrentDocument = observations.filter(observation => observation.documentId === document.id);
              if (observationForCurrentDocument.length) {
                return {...document, observation: observationForCurrentDocument[0].text};
              }
              return document;
            });
        }),
        map((documentsFinalized: Document[]) =>
          observationsLinked({documents: documentsFinalized})
        )
      )
    ),
  ));
}
