import { Action, createReducer, on } from '@ngrx/store';
import { TaxFormSummary } from '../model/tax-form-summary.model';
import { getSelectedUserSuccess, getUserStatusesSuccess } from './admin.actions';
import { User } from '../../authentication/model/user.model';

export default interface AdminState {
  userStatuses: TaxFormSummary[];
  selectedUser: User;
}

export const initialState: AdminState = {
  userStatuses: null,
  selectedUser: null
};

const adminReducer = createReducer(
  initialState,
  on(
    getUserStatusesSuccess,
    (state, action) => (
      { ...state, userStatuses: action.statuses }
    )),
  on(
    getSelectedUserSuccess,
    (state, action) => (
      { ...state, selectedUser: action.user }
    )),
);

export function reducer(state: AdminState | undefined, action: Action) {
  return adminReducer(state, action);
}
