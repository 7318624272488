export default {
  default: ['1', '2', '3', '4', '5'],
  activities: {
    employee: ['6'],
    unemployed: ['7'],
    independent: ['8'],
    retired: ['9', '10'],
  },
  advancePayments: ['11'],
  costs: {
    medicalAndDentalExpenses: ['12'],
    training: ['13'],
    donations: ['14'],
    unionDues: ['15'],
  },
  expenditurePatrimony: {
    thirdPillarA: ['16'],
    thirdPillarB: ['17'],
    contributionsLPP: ['18'],
    investment: ['19', '20'],
    stakesInCompanies: ['21'],
    loans: ['22'],
  },
  familyExpenses: {
    childSupportExpense: ['23'],
    childcareCosts: ['24'],
  },
  familyIncome: {
    familyAllowances: ['25'],
    childSupportIncome: ['26'],
  },
  marriedQuestion: [],
  minergie: ['27'],
  mortgage: ['28'],
  otherIncome: {
    rentAssistance: ['29'],
    healthInsuranceReimbursements: ['30'],
    lotteryWinnings: ['31'],
  },
  propertyOwner: ['32'],
  propertyPurchase: ['33'],
  rent: ['34'],
  taxDebts: {
    borrowingFromAThirdParty: ['35'],
    creditCardOrLoanDebts: ['36'],
    mortgageDebts: ['37'],
    taxDebtsStillOutstanding: ['38'],
  }
};
