import { Action, createReducer, on } from '@ngrx/store';
import { submitTaxDocumentsForm, submitTaxProfile } from './form.actions';
import { TaxDocumentsForm, TaxProfileForm } from '../model/form.model';
import {
  getTaxDocumentsDataSuccess,
  getTaxProfileDataSuccess
} from '../../datastore/store/datastore.actions';
import { signOutSuccess } from '../../authentication/store/authentication.actions';

export default interface FormState {
  taxProfileForm: TaxProfileForm;
  taxDocumentsForm: TaxDocumentsForm;
}

export const initialState: FormState = {
  taxProfileForm: null,
  taxDocumentsForm: null,
};

const formReducer = createReducer(
  initialState,
  on(
    submitTaxProfile,
    getTaxProfileDataSuccess,
    (state, action) => (
      {...state, taxProfileForm: action.taxProfileForm}
    )),
  on(
    submitTaxDocumentsForm,
    getTaxDocumentsDataSuccess,
    (state, action) => (
      {...state, taxDocumentsForm: action.taxDocumentsForm}
    )),
  on(
    signOutSuccess,
    (state) => (
      {...state, taxProfileForm: null, taxDocumentsForm: null}
    ))
);

export function reducer(state: FormState | undefined, action: Action) {
  return formReducer(state, action);
}
