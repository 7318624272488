import { Action, createReducer, on } from '@ngrx/store';
import { createToken, paymentConfirmed, paymentFailed, tokenCreationFailed } from './payment.actions';
import { paymentCompleted, paymentError, paymentIdle, paymentProcessing } from '../model/payment.model';

export default interface PaymentState {
  currentState: string;
}

export const initialState: PaymentState = {
  currentState: paymentIdle,
};

const paymentReducer = createReducer(
  initialState,
  on(
    createToken,
    (state) => (
      {...state, currentState: paymentProcessing}
    )),
  on(paymentConfirmed,
    (state) => (
      {...state, currentState: paymentCompleted}
    )),
  on(tokenCreationFailed, paymentFailed,
    (state) => (
      {...state, currentState: paymentError}
    )),
);

export function reducer(state: PaymentState | undefined, action: Action) {
  return paymentReducer(state, action);
}
