import { NgModule } from '@angular/core';
import { BreakLinesPipe, ReplacePipe, StartsWithPipe, SubstringPipe } from './string.pipe';

@NgModule({
  declarations: [
    ReplacePipe,
    StartsWithPipe,
    SubstringPipe,
    BreakLinesPipe,
  ],
  imports: [
  ],
  exports: [
    ReplacePipe,
    StartsWithPipe,
    SubstringPipe,
    BreakLinesPipe,
  ]
})
export class StringPipeModule {
}
