import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '../model/user.model';
import firebase from 'firebase';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private auth: AngularFireAuth,
  ) {
  }

  signUp(email: string, password: string): Observable<UserCredential> {
    return from(this.auth.createUserWithEmailAndPassword(email, password));
  }

  signIn(email: string, password: string): Observable<UserCredential> {
    return from(this.auth.signInWithEmailAndPassword(email, password));
  }

  forgotPassword(email: string): Observable<void> {
    return from(this.auth.sendPasswordResetEmail(email));
  }

  signOut(): Observable<void> {
    return from(this.auth.signOut());
  }

  isLoggedIn(): Observable<boolean> {
    return this.getCurrentUser().pipe(map(user => Boolean(user)));
  }

  getCurrentUser(): Observable<User> {
    return from(this.auth.authState).pipe(
      mergeMap(() => {
        return from(this.auth.currentUser).pipe(
          map(firebaseUser => {
            if (firebaseUser !== null) {
              return {
                email: firebaseUser.email,
                userId: firebaseUser.uid,
                refreshToken: firebaseUser.refreshToken
              } as User;
            }
            throw Error('not logged in');
          })
        );
      })
    );
  }
}
