import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TaxProfileForm } from '../model/form.model';

@Component({
  selector: 'leaf-tax-profile',
  template: `
    <ul *ngIf="taxProfile">
      <li *ngIf="children"><mat-icon>check_circle_outline</mat-icon><span>{{ children }}</span></li>
      <li *ngIf="property"><mat-icon>check_circle_outline</mat-icon><span>{{ property }}</span></li>
      <li *ngIf="investments"><mat-icon>check_circle_outline</mat-icon><span>{{ investments }}</span></li>
    </ul>
    <button mat-button routerLink="/tax-profile">Modifier les critères</button>
  `,
  styles: [`
    ul {
      max-width: 384px;
      margin: 0 auto;
      padding: 0;
      list-style-type: none;
      text-align: left;
    }

    ul li {
      color: white;
      margin-bottom: 4px;
    }

    mat-icon {
      margin-right: 8px;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }

    button {
      display: block;
      margin: 12px auto 0;
      color: white;
    }
  `]
})
export class TaxProfileComponent implements OnChanges {

  @Input() taxProfile: TaxProfileForm;

  children: string;
  property: string;
  investments: string;

  constructor() { }

  ngOnChanges(): void {
    if (this.taxProfile) {
      this.children = this.taxProfile.childrenInput ? 'Avoir des enfants à charge' : 'Ne pas avoir d\'enfant à charge';
      this.property = this.taxProfile.propertyOwnerInput ? `Avoir ${this.taxProfile.propertyNumberInput} bien(s) immobilier(s)` : 'Ne pas avoir de bien immobilier';
      this.investments = this.taxProfile.investmentsInput ? this.taxProfile.investmentsInput
        .replace('Oui,', 'Avoir des titres ou placements')
        .replace('Non, je n\'ai pas', 'Ne pas avoir') : '';
    }
  }
}
