import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { submitTaxDocumentsForm, submitTaxProfile } from './form.actions';

@Injectable({
  providedIn: 'root'
})
export class FormEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
  ) {
  }

  processTaxProfileEffect$ = createEffect(() => this.actions$.pipe(
    ofType(submitTaxProfile.type),
    tap(() => this.router.navigate(['/activation']))
  ), {dispatch: false});

  processTaxDocumentsFormEffect$ = createEffect(() => this.actions$.pipe(
    ofType(submitTaxDocumentsForm.type),
    tap(() => this.router.navigate(['/documents']))
  ), {dispatch: false});
}
