import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Document, ImportedDoc, Observation, Status } from '../model/documents.model';
import { MatSort } from '@angular/material/sort';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, takeUntil } from 'rxjs/operators';
import { FileInput } from 'ngx-material-file-input';
import { UploadService } from '../service/upload.service';
import { StorageService } from '../service/storage.service';
import { environment } from '../../../environments/environment';
import { EMPTY, Observable, Subject } from 'rxjs';

@Component({
  selector: 'leaf-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss']
})
export class ListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @Input() userId: string;
  @Input() columnsToDisplay: string[];
  @Input() columnsNames: string[];
  @Input() documents: Document[];
  @Output() observationChange = new EventEmitter<Observation>();
  @Output() uploadingStart = new EventEmitter<string>();
  @Output() uploadingSuccess = new EventEmitter<ImportedDoc>();
  @Output() uploadingFail = new EventEmitter<string>();

  dataSource: MatTableDataSource<Document>;
  expandedElement: string;
  pictureForm: FormGroup[] = [];
  filesToUpload: File[];
  uploadProgress$: Observable<number>;
  destroy$: Subject<null> = new Subject();

  UPLOADING = Status.Uploading;
  NO_FILE = Status.NoFile;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private uploadService: UploadService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents) {
      const documents = changes.documents.currentValue;
      this.dataSource.data = documents;
      documents.forEach(document => this.configureReactiveForm(document));
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
  }

  onFilesDropped(documentId, files: File[]) {
    this.pictureForm[documentId].controls.files.patchValue(new FileInput(files));
    this.pictureForm[documentId].controls.files.updateValueAndValidity();
    this.uploadFile(documentId);
  }

  handleFileChange(files) {
    this.filesToUpload = files;
  }

  configureReactiveForm(document: Document) {
    if (this.pictureForm[document.id] === undefined) {
      this.pictureForm[document.id] = this.formBuilder.group({
        files: [
          null,
          [Validators.required, this.imageAndPDF.bind(this)]
        ],
        observation: [document.observation]
      });
      this.pictureForm[document.id]
        .get('files')
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((newUpload: FileInput) => this.handleFileChange(newUpload.files));
    }
    else {
      this.pictureForm[document.id].controls.observation.setValue(document.observation);
    }
  }

  uploadFile(documentId) {
    this.uploadingStart.emit(documentId);

    const mediaFolderPath = `${environment.storage.path}/${this.userId}/documents/`;

    this.filesToUpload.forEach(fileToUpload => {
      const { downloadUrl$, uploadProgress$ } = this.storageService.uploadFileAndGetMetadata(mediaFolderPath, fileToUpload);
      this.uploadProgress$ = uploadProgress$;
      downloadUrl$
        .pipe(
          takeUntil(this.destroy$),
          catchError((error) => {
            console.error(error.message);
            this.uploadingFail.emit(documentId);
            return EMPTY;
          }),
        )
        .subscribe((downloadUrl) => {
          const importedDoc = new ImportedDoc(this.userId, documentId, fileToUpload.name, downloadUrl);
          this.uploadingSuccess.emit(importedDoc);
        });
    });
  }

  observationChanged(documentId: string) {
    const observation = new Observation(this.userId, documentId, this.pictureForm[documentId].controls.observation.value);
    this.observationChange.emit(observation);
  }

  private imageAndPDF(filesControl: AbstractControl): { [key: string]: boolean } | null {
    if (filesControl.value && filesControl.value.files.length) {
      return filesControl.value.files
        .reduce((acc: boolean, file: File) => acc && this.uploadService.validateFile(file), true) ? null : { files: true };
    }
    return;
  }
}
