import { Document, Workbook } from './documents.model';

export default [
  {
    id: '1',
    name: 'Formulaire de déclaration fiscale',
    description: 'Formulaire que vous recevez de l\'administration fiscale de votre canton avec vos identifiants et codes de déclaration.',
    workbook: Workbook.Tax,
    required: true,
  } as Document,
  {
    id: '2',
    name: 'Copie de la déclaration fiscale précédente',
    description: 'Copie de la précèdente déclaration fiscale nous permettant de corriger d\'éventuelles erreurs entre deux périodes fiscales.',
    workbook: Workbook.Tax,
    required: true,
  } as Document,
  {
    id: '3',
    name: 'Copie de taxation précédente',
    description: 'Copie de votre dernière décision de taxation envoyée par l\'administration fiscale.',
    workbook: Workbook.Tax,
    required: false,
  } as Document,
  {
    id: '4',
    name: 'Attestation de l\'assurance-maladie mentionnant les primes versées',
    description: 'Attestation fournie par votre assurance qui mentionne le total des primes payées.',
    workbook: Workbook.Insurance,
    required: true,
  } as Document,
  {
    id: '5',
    name: 'Attestations bancaires mentionnant le solde et les intérêts perçus',
    description: '',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '6',
    name: 'Certificats de salaire',
    description: 'Attestation fournie par votre employeur qui mentionne vos revenus perçus.',
    workbook: Workbook.Work_and_activities,
    required: true,
  } as Document,
  {
    id: '7',
    name: 'Attestation de chômage',
    description: 'Attestation fournie par la caisse de chômage qui mentionne le total des allocations perçues.',
    workbook: Workbook.Work_and_activities,
    required: true,
  } as Document,
  {
    id: '8',
    name: 'Bilan et compte de Pertes & Profits',
    description: 'Rapport de gestion fourni par votre fiduciaire ou votre comptable.',
    workbook: Workbook.Work_and_activities,
    required: true,
  } as Document,
  {
    id: '9',
    name: 'Attestations de rentes perçues',
    description: 'Attestation fournie par votre caisse AVS, AI, LPP, etc. qui mentionne les rentes perçues.',
    workbook: Workbook.Pension,
    required: true,
  } as Document,
  {
    id: '10',
    name: 'Attestations autres revenus éventuels',
    description: 'Attestations qui peuvent être fournie par des organismes de prévoyance et qui mentionnent les rentes perçues. (3ème pilier, rentes viagères, etc.)',
    workbook: Workbook.Work_and_activities,
    required: true,
  } as Document,
  {
    id: '11',
    name: 'Relevés des acomptes payés',
    description: 'Relevé de compte fourni par l\'administration fiscale avec le total des acomptes payés durant l\'année. Vous le recevez avec le formulaire de déclaration d\'impôt.',
    workbook: Workbook.Tax,
    required: false,
  } as Document,
  {
    id: '12',
    name: 'Décompte des frais médicaux et dentaires à votre charge',
    description: 'Attestation fournie par votre assurance qui mentionne le total des frais médicaux annuels à votre charge.',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '13',
    name: 'Justificatifs des frais de formation',
    description: 'Factures de scolarisation ou des formations suivies durant l\'année.',
    workbook: Workbook.Work_and_activities,
    required: false,
  } as Document,
  {
    id: '14',
    name: 'Décompte des dons versés',
    description: 'Quittances des dons effectués.',
    workbook: Workbook.Bank_and_financies,
    required: true,
  } as Document,
  {
    id: '15',
    name: 'Cotisations syndicales',
    description: '',
    workbook: Workbook.Work_and_activities,
    required: false,
  } as Document,
  {
    id: '16',
    name: 'Attestation de cotisations au 3ème pilier A',
    description: 'Attestation fournie par votre banque ou votre assurance qui mentionne le total des cotisations payées.',
    workbook: Workbook.Pension,
    required: true,
  } as Document,
  {
    id: '17',
    name: 'Attestation de cotisations d\'assurance vie (3ème pilier B)',
    description: 'Attestation fournie par votre assurance qui mentionne la valeur de rachat est les cotisations payées.',
    workbook: Workbook.Pension,
    required: true,
  } as Document,
  {
    id: '18',
    name: 'Attestation de rachat de prévoyance LPP',
    description: 'Attestation fournie par votre caisse LPP qui mentionne les rachats de cotisations payées.',
    workbook: Workbook.Pension,
    required: true,
  } as Document,
  {
    id: '19',
    name: 'Relevés fiscaux',
    description: 'Attestation fiscale fournie par votre banque qui mentionne votre fortune (actions, obligations, fonds de placements, etc.) et vos revenus de titres perçus.',
    workbook: Workbook.Bank_and_financies,
    required: true,
  } as Document,
  {
    id: '20',
    name: 'Numéraires, métaux précieux, bijoux, oeuvres d\'art, etc.',
    description: 'Attestations mentionnant la valeur de ces biens que vous détenez.',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '21',
    name: 'Participations dans des sociétés et les éventuels dividendes perçues',
    description: 'Actions, obligations, options que vous auriez dans des sociétés ainsi que les éventuels revenus perçus de ces titres durant l\'année.',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '22',
    name: 'Attestation de solde et intérêts pour les prêts octroyés à une tierce personne',
    description: 'Convention de prêt ou autre',
    workbook: Workbook.Tax,
    required: false,
  } as Document,
  {
    id: '23',
    name: 'Pensions alimentaires versée',
    description: 'Copie du jugement du Tribunal ainsi que les preuves de paiements effectués durant l\'année ainsi que les coordonnées du bénéficiaire (Nom, Prénom, Adresse, numéro de contribuable).',
    workbook: Workbook.Family,
    required: true,
  } as Document,
  {
    id: '24',
    name: 'Justificatifs des frais de garde',
    description: 'Quittances de paiements de la crèche, de la nourrice ou des parascolaires.',
    workbook: Workbook.Family,
    required: true,
  } as Document,
  {
    id: '25',
    name: 'Allocations familiales',
    description: 'Attestation fournie par la caisse AVS qui mentionne le total des allocations perçues.',
    workbook: Workbook.Family,
    required: false,
  } as Document,
  {
    id: '26',
    name: 'Pensions alimentaires perçues',
    description: 'Copie du jugement du Tribunal ainsi que les preuves de paiements reçus durant l\'année ainsi que les coordonnées du débiteur (Nom, Prénom, Adresse, numéro de contribuable).',
    workbook: Workbook.Family,
    required: false,
  } as Document,
  {
    id: '27',
    name: 'Certificat Minergie',
    description: '',
    workbook: Workbook.Housing,
    required: false,
  } as Document,
  {
    id: '28',
    name: 'Justificatifs des frais d\'entretien/rénovation et les charges de copropriété',
    description: 'Factures des frais d\'entretiens et/ou de rénovations durant l\'année.',
    workbook: Workbook.Housing,
    required: true,
  } as Document,
  {
    id: '29',
    name: 'Attestation de logement',
    description: '',
    workbook: Workbook.Housing,
    required: false,
  } as Document,
  {
    id: '30',
    name: 'Subside d\'assurance-maladie',
    description: '',
    workbook: Workbook.Insurance,
    required: false,
  } as Document,
  {
    id: '31',
    name: 'Gains de loterie',
    description: 'Quittances ou attestations de gains.',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '32',
    name: 'Valeur fiscale du ou des biens immobiliers',
    description: 'Attestation que vous recevez de l\'administration fiscale de votre canton avec la valeur fiscale et la valeur locative de votre bien immobilier.',
    workbook: Workbook.Tax,
    required: true,
  } as Document,
  {
    id: '33',
    name: 'Contrat de vente du ou des biens immobiliers',
    description: 'Acte de vente du bien immobilier fourni par le notaire.',
    workbook: Workbook.Housing,
    required: false,
  } as Document,
  {
    id: '34',
    name: 'Revenus locatifs perçus',
    description: 'Loyers encaissés durant l\'année de votre bien immobilier. Si vous avez plusieurs biens immobiliers ce sont les comptes de gestion des immeubles ou des appartements en location.',
    workbook: Workbook.Housing,
    required: false,
  } as Document,
  {
    id: '35',
    name: 'Attestation de solde et intérêts pour les emprunts auprès d\'une tierce personne',
    description: 'Convention de prêt ou autre',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '36',
    name: 'Attestation de solde et intérêts pour les crédits bancaires',
    description: 'Exemples: carte de crédit, emprunt',
    workbook: Workbook.Bank_and_financies,
    required: false,
  } as Document,
  {
    id: '37',
    name: 'Attestation de solde et intérêts pour les dettes hypothécaires',
    description: 'Attestation fournie par votre banque ou votre assurance qui mentionne le montant de l\'hypothèque et les intérêts payés.',
    workbook: Workbook.Housing,
    required: true,
  } as Document,
  {
    id: '38',
    name: 'Dettes fiscales',
    description: 'Attestation fiscale de votre carte de crédit ou autre dette mentionnant le solde et les intérêts ',
    workbook: Workbook.Tax,
    required: true,
  } as Document,
];
