import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxHomeComponent } from './tax-home.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FormModule } from '../form/form.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContentModule } from '../content/content.module';


@NgModule({
  declarations: [
    TaxHomeComponent,
  ],
  imports: [
    CommonModule,
    ContentModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    RouterModule,
    FormModule,
  ]
})
export class TaxHomeModule { }
