import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { Action } from '@ngrx/store';
import {
  startTaxProfile,
  submitTaxProfile,
  changeResidency,
  changeNationality,
  changeChildren,
  changePropertyOwner,
  changeInvestments, startTaxDocumentsForm, submitTaxDocumentsForm, changeValueTaxDocumentsForm, changePropertyNumber,
} from '../../form/store/form.actions';
import { environment } from '../../../environments/environment';
import {
  forgotPassword,
  missingDocumentClick,
  signIn,
  signUp
} from '../../authentication/store/authentication.actions';
import { uploadingFileToDocument } from '../../documents/store/documents.actions';
import {
  createToken,
  paymentConfirmed,
  paymentFailed,
  tokenCreated,
  tokenCreationFailed
} from '../../payment/store/payment.actions';
import { faqClicked } from '../../content/store/content.actions';
import { navClicked } from '../../navigation-rail/store/navigation-rail.actions';
import { callToActionClicked } from '../../tax-home/store/tax-home.actions';
import { activationClicked, priceFixed } from '../../activation/store/activation.actions';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private analytics: AngularFireAnalytics,
  ) {
  }

  updateUserProperties(action: Action, isAdmin: boolean): void {
    const userProperties = this.getProperties(action);
    if (environment.production && !isAdmin) {
      this.analytics.setUserProperties(userProperties);
    } else {
      console.log(`User properties locally:`, userProperties);
    }
  }

  logAction(action: Action, isAdmin: boolean) {
    const event = this.getEvent(action);
    const payload = this.getPayload(action);
    if (environment.production && !isAdmin) {
      this.analytics.logEvent(event, payload);
    } else {
      console.log(`Event logged locally: ${event}, payload:`, payload);
    }
  }

  getProperties(action: Action): any {
    switch (action.type) {
      case changeResidency.type:
        return {
          residency: (action as ReturnType<typeof changeResidency>).residency,
        };
      case changeNationality.type:
        return {
          isSwiss: (action as ReturnType<typeof changeNationality>).nationality,
        };
      case changeChildren.type:
        return {
          hasChildren: (action as ReturnType<typeof changeChildren>).children,
        };
      case changePropertyOwner.type:
        return {
          isPropertyOwner: (action as ReturnType<typeof changePropertyOwner>).propertyOwner,
        };
      case changePropertyNumber.type:
        return {
          propertyCount: (action as ReturnType<typeof changePropertyNumber>).propertyNumber,
        };
      case changeInvestments.type:
        return {
          investments: (action as ReturnType<typeof changeInvestments>).investments,
        };
      case priceFixed.type:
        return {
          fixedPrice: (action as ReturnType<typeof priceFixed>).price,
        };
      case changeValueTaxDocumentsForm.type:
        const data = (action as ReturnType<typeof changeValueTaxDocumentsForm>);
        return {
          [data.controlName]: data.value,
        };
      case paymentConfirmed.type:
        return {
          hasPaid: true,
          transactionId: (action as ReturnType<typeof paymentConfirmed>).transactionId,
        };
      default:
        return {};
    }
  }

  getEvent(action: Action): string {
    switch (action.type) {
      // Official event
      case navClicked.type:
        return 'page_view';
      case callToActionClicked.type:
        return 'screen_view';
      case priceFixed.type:
        return 'add_to_cart';
      case faqClicked.type:
        return 'select_content';
      case activationClicked.type:
        return 'begin_checkout';
      case createToken.type:
        return 'checkout_progress';
      case paymentConfirmed.type:
        return 'purchase';
      case paymentFailed.type:
        return 'exception';
      case signIn.type:
        return 'login';
      case signUp.type:
        return 'sign_up';
      // Tax profile form
      case startTaxProfile.type:
        return 'tax_profile_started';
      case submitTaxProfile.type:
        return 'tax_profile_submitted';
      case changeResidency.type:
        return 'tax_profile_residency_answered';
      case changeNationality.type:
        return 'tax_profile_nationality_answered';
      case changeChildren.type:
        return 'tax_profile_children_answered';
      case changePropertyOwner.type:
        return 'tax_profile_property_owner_answered';
      case changeInvestments.type:
        return 'tax_profile_investments_answered';
      // Tax document form
      case startTaxDocumentsForm.type:
        return 'tax_documents_form_started';
      case submitTaxDocumentsForm.type:
        return 'tax_documents_form_submitted';
      case changeValueTaxDocumentsForm.type:
        const controlName = (action as ReturnType<typeof changeValueTaxDocumentsForm>).controlName;
        return 'tax_documents_form_' + controlName + '_answered';
      // Documents
      case uploadingFileToDocument.type:
        return 'uploading_document';
      // Others
      case forgotPassword.type:
        return 'forgot_password';
      case missingDocumentClick.type:
        return 'missing_document';
      default:
        return action.type;
    }
  }

  getPayload(action: Action): any {
    switch (action.type) {
      case navClicked.type: // page_view
        return {
          page_title: (action as ReturnType<typeof navClicked>).pageName
        };
      case callToActionClicked.type: // screen_view
        return {
          app_name: 'leaf',
          screen_name: (action as ReturnType<typeof callToActionClicked>).buttonId
        };
      case priceFixed.type: // add_to_cart
        return {
          value: (action as ReturnType<typeof priceFixed>).price
        };
      case activationClicked.type: // begin_checkout
        return {
          value: (action as ReturnType<typeof activationClicked>).price,
          items: [
            {
              name: (action as ReturnType<typeof activationClicked>).buttonId,
              category: 'activation'
            }
          ]
        };
      case faqClicked.type: // select_content
        return {
          content_type: 'faq',
          items: [
            {
              name: (action as ReturnType<typeof faqClicked>).question,
              category: 'faq'
            }
          ]
        };
      case paymentConfirmed.type: // purchase
        return {
          transaction_id: (action as ReturnType<typeof paymentConfirmed>).transactionId,
          value: (action as ReturnType<typeof paymentConfirmed>).price
        };
      case paymentFailed.type: // exception
        return {
          description: 'payment failed'
        };
      default:
        return null;
    }
  }

}
