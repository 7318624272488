import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { FormSection, Question, TaxDocumentsForm, TaxProfileForm } from '../model/form.model';
import formSections from './../model/tax-documents-form.constant';
import { changeValueTaxDocumentsForm, startTaxDocumentsForm, submitTaxDocumentsForm } from '../store/form.actions';
import { getTaxForms } from '../store/form.selector';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'leaf-tax-documents-form',
  templateUrl: './tax-documents-form.component.html',
  styleUrls: ['./../form.component.scss']
})
export class TaxDocumentsFormComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private myStepper: MatStepper;

  INDEX_PROPERTY = 2;
  INDEX_FAMILY = 3;
  MARRIED_QUESTION = 'marriedQuestion';

  children: string;
  property: string;
  formSections = formSections;
  formGroups: FormGroup[];
  taxDocumentsForm: TaxDocumentsForm;

  taxFormsSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.formGroups = this.getFormGroupFromSections(this.formSections);
    this.taxDocumentsForm = new TaxDocumentsForm();
  }

  private getFormGroupFromSections(sections: FormSection[]): FormGroup[] {
    return sections.reduce((acc: FormGroup[], section: FormSection): FormGroup[] => {
      const controlsConfig = this.getControlsConfigFromQuestions(section.questions);
      acc.push(this.formBuilder.group(controlsConfig));
      return acc;
    }, [] as FormGroup[]);
  }

  private getControlsConfigFromQuestions(questions: Question[]) {
    const fieldNameList = Object.keys(Reflect.construct(TaxDocumentsForm, []));
    return questions.reduce((acc, question) => {
      if (!fieldNameList.includes(question.controlName)) {
        console.error(`controlName: ${question.controlName} from questions constant does not exist on class`);
      }
      return ({...acc, [question.controlName]: [question.initialValue, question.validators] });
    }, {});
  }

  ngOnInit(): void {
    this.store.dispatch(startTaxDocumentsForm());
    this.taxFormsSubscription = this.store.pipe(
      select(getTaxForms),
    ).subscribe(
      ([taxProfile, taxDocument]: [TaxProfileForm, TaxDocumentsForm]) => {
        if (taxDocument) {
          this.formGroups.map(group => {
            Object.keys(group.controls).map(controlName => {
              if (taxDocument[controlName] != null) {
                group.controls[controlName].setValue(taxDocument[controlName]);
                this.taxDocumentsForm = {...this.taxDocumentsForm, [controlName]: taxDocument[controlName]};
              }
            });
            group.updateValueAndValidity();
          });
        }
        if (taxProfile) {
          this.children = taxProfile.childrenInput ? 'avoir des enfants à charge' : 'ne pas avoir d\'enfant à charge';
          if (taxProfile.childrenInput === false) {
            const formGroupFamily = this.formGroups[this.INDEX_FAMILY];
            formGroupFamily.disable();
            Object.keys(formGroupFamily.controls).forEach(controlName => {
              formGroupFamily.controls[controlName].setValue([]);
              this.taxDocumentsForm = {...this.taxDocumentsForm, [controlName]: []};
            });
            formGroupFamily.updateValueAndValidity();
          }
          this.property = taxProfile.propertyOwnerInput ? `avoir ${taxProfile.propertyNumberInput} bien(s) immobilier(s)` : 'ne pas avoir de biens immobiliers';
          if (taxProfile.propertyOwnerInput === false) {
            const formGroupProperty = this.formGroups[this.INDEX_PROPERTY];
            formGroupProperty.disable();
            Object.keys(formGroupProperty.controls).forEach(controlName => {
              formGroupProperty.controls[controlName].setValue(false);
              this.taxDocumentsForm = {...this.taxDocumentsForm, [controlName]: false};
            });
            formGroupProperty.updateValueAndValidity();
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.taxFormsSubscription.unsubscribe();
  }

  valueChanged(controlName, value: boolean | number | string) {
    this.store.dispatch(changeValueTaxDocumentsForm({controlName, value}));
    this.taxDocumentsForm = {...this.taxDocumentsForm, [controlName]: value};
  }

  formSubmit() {
    this.store.dispatch(submitTaxDocumentsForm({taxDocumentsForm: this.taxDocumentsForm}));
  }
}
