import { Question } from './form.model';

export default {
  residencyQuestion: {
    title: 'Quel est votre canton de résidence ?',
    controlName: 'residency'
  } as Question,
  residencyValues: [
    'Fribourg',
    'Genève',
    'Neuchâtel',
    'Valais',
    'Vaud',
    'Autre canton'
  ],
  nationalityQuestion: {
    title: 'Etes-vous de nationalité Suisse ou titulaire d\'un permis C ?',
    controlName: 'nationality'
  } as Question,
  childrenQuestion: {
    title: 'Avez-vous des enfants à charge ?',
    controlName: 'children'
  } as Question,
  propertyOwnerQuestion: {
    title: 'Êtes-vous propriétaire ?',
    controlName: 'propertyOwner'
  } as Question,
  propertyNumberQuestion: {
    title: 'Combien de biens immobiliers avez-vous ?',
    controlName: 'propertyNumber'
  } as Question,
  investmentsQuestion: {
    title: 'Avez-vous des titres ou des placements ?',
    controlName: 'investments'
  } as Question,
  investmentsValues: [
    'Oui, avec relevés fiscaux',
    'Oui, sans relevés fiscaux',
    'Non, je n\'ai pas de titre ou de placement'
  ],
};
