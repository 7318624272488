import { Component, OnInit } from '@angular/core';
import questionsAndAnswers from './model/questions-and-answers.constant';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { faqClicked } from '../store/content.actions';

@Component({
  selector: 'leaf-faq',
  template: `
    <div class="questions-and-answers">
      <h2>Vos questions nos réponses</h2>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel *ngFor="let qna of questionsAndAnswers">
          <mat-expansion-panel-header (click)="faqClick(qna.question)">
            <mat-panel-title>{{ qna.question }}</mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ qna.answer }}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  `,
  styles: [`
    .questions-and-answers {
      text-align: center;
    }

    .questions-and-answers mat-accordion {
      display: block;
      max-width: 768px;
      margin: 16px auto;
    }
  `]
})
export class FaqComponent implements OnInit {

  questionsAndAnswers = questionsAndAnswers;

  constructor(
    private store: Store<State>,
  ) { }

  ngOnInit(): void {
  }

  faqClick(question) {
    this.store.dispatch(faqClicked({question}));
  }
}
