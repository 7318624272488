import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'leaf-activity',
  template: `
    <div class="activity">
      <h4>{{ name }}</h4>
      <p>{{ status }}</p>
      <mat-progress-bar [value]="percent" color="accent"></mat-progress-bar>
      <div *ngIf="buttonText" class="btn-container">
        <button fxHide fxShow.gt-xs mat-stroked-button (click)="buttonCLicked($event)" [disabled]="buttonDisabled">{{ buttonText }}</button>
        <button fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="activityMenu" [matTooltip]="message">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #activityMenu="matMenu">
          <button mat-menu-item (click)="buttonCLicked($event)" [disabled]="buttonDisabled">{{ buttonText }}</button>
        </mat-menu>
        <p fxHide fxShow.gt-xs class="message" *ngIf="message">{{ message }}</p>
      </div>
    </div>
  `,
  styleUrls: ['activity.component.scss']
})
export class ActivityComponent implements OnInit {

  @Input() name: string;
  @Input() status: string;
  @Input() message: string;
  @Input() percent: number;
  @Input() buttonText: string;
  @Input() buttonDisabled: boolean;
  @Output() buttonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  buttonCLicked(event: any) {
    this.buttonClick.emit(event);
  }

}
