import { Injectable } from '@angular/core';
import values from '../../form/model/tax-profile-form.constant';
import { TaxProfileForm } from '../../form/model/form.model';

@Injectable({
  providedIn: 'root'
})
export class ActivationService {

  investmentsImpactTable = [125, 150, 100];

  constructor() {
  }

  fixPrice(inputs: TaxProfileForm): number {
    const indexInvestments = values.investmentsValues.indexOf(inputs.investmentsInput);
    const price = this.investmentsImpactTable[indexInvestments] + 25 * inputs.propertyNumberInput;
    return price % 10 === 0 ? (price - 1) : price;
  }
}
