import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export class Documents {
  activities: Activity[] = [];
  list: Document[] = [];

  constructor(activityName: string, list: Document[]) {
    this.activities.push(new Activity(activityName, list.map(document => document.id)));
    this.list = list;
  }
}

export class Activity {
  name: string;
  idList: string[];

  constructor(name: string, idList: string[]) {
    this.name = name;
    this.idList = idList;
  }
}

export class Document {
  id: string;
  name: string;
  description: string;
  workbook: Workbook;
  required: boolean;
  status: number | Status;
  lastChange: Date;
  observation: string;
  observationStatus: string;
  files: DocumentFile[];

  constructor(document: Document) {
    this.id = document.id;
    this.name = document.name;
    this.description = document.description;
    this.workbook = document.workbook;
    this.required = document.required;
    this.status = Status.NoFile;
    this.lastChange = null;
    this.observation = null;
    this.observationStatus = null;
    this.files = [];
  }
}

export class Observation {
  userId: string;
  documentId: string;
  text: string;

  constructor(userId: string, documentId: string, text: string) {
    this.userId = userId;
    this.documentId = documentId;
    this.text = text;
  }
}

export interface DocumentFile {
  name: string;
  path: string;
}

export class ImportedDoc {
  userId: string;
  documentId: string;
  name: string;
  path: string;
  lastChange: Date | Timestamp;

  constructor(userId: string, documentId: string, name: string, path: string) {
    this.userId = userId;
    this.documentId = documentId;
    this.name = name;
    this.path = path;
    this.lastChange = new Date();
  }
}

export enum Workbook {
  Bank_and_financies = 'Banque et finances',
  Family = 'Famille',
  Housing = 'Immobilier',
  Insurance = 'Assurance',
  Other = 'Autre',
  Pension = 'Prévoyance',
  Tax = 'Fiscalité',
  Work_and_activities = 'Travail et activités',
}

export enum Status {
  NoFile = 'Aucun fichier',
  Uploading = 'Importation...',
  Failed = 'Echec',
}
