import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-chat',
  template: `
    <div class="container">
      <mat-icon>chat_bubble</mat-icon>
      <h3>La messagerie sera disponible prochainement</h3>
      <p>Vous pouvez néanmoins contacter un de nos conseillers par mail</p>
      <a mat-button color="primary" href="mailto:info@leaf.swiss?subject=Question">Poser une question</a>
    </div>
  `,
  styles: [`
    .container {
      display: flex;
      height: 100%;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    mat-icon {
      margin: 0 auto 32px;
      transform: scale(4);
      color: #ddd;
    }

    a {
      display: inline-block;
      margin: 16px;
      text-transform: uppercase;
    }
  `]
})
export class ChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
