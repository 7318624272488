import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { State } from '../reducers';
import { getAuthUser } from '../authentication/store/authentication.selector';
import { map } from 'rxjs/operators';
import { navClicked } from './store/navigation-rail.actions';
import { isAdmin } from '../admin/service/admin.helper';

@Component({
  selector: 'leaf-navigation-rail',
  template: `
    <div class="navigation-rail-container">
      <nav *ngIf="(loggedIn$ | async) && path === 'profile' || path === 'messages' || path === 'documents' || path === 'admin'" class="navigation-rail">
        <button mat-button
                class="navigation-rail-link"
                [ngClass]="{'selected': path === 'profile'}"
                (click)="showPage('profile')">
          <mat-icon>account_circle</mat-icon>
          <div class="navigation-rail-text">
            <h5>Profil</h5>
            <p>Consulter votre profil</p>
          </div>
        </button>
        <button mat-button
                class="navigation-rail-link"
                [ngClass]="{'selected': path === 'documents'}"
                (click)="showPage('documents')">
          <mat-icon>folder</mat-icon>
          <div class="navigation-rail-text">
            <h5>Documents</h5>
            <p>Gérer vos documents</p>
          </div>
        </button>
        <button mat-button
                class="navigation-rail-link"
                [ngClass]="{'selected': path === 'messages'}"
                (click)="showPage('messages')">
          <mat-icon>chat_bubble</mat-icon>
          <div class="navigation-rail-text">
            <h5>Messages</h5>
            <p>Demander conseil</p>
          </div>
        </button>
        <button mat-button
                class="navigation-rail-link"
                *ngIf="admin$ | async"
                [ngClass]="{'selected': path === 'admin'}"
                (click)="showPage('admin')">
          <mat-icon>wc</mat-icon>
          <div class="navigation-rail-text">
            <h5>Admin</h5>
            <p>Users and stuff</p>
          </div>
        </button>
      </nav>
      <main class="navigation-rail-content" #content>
        <ng-content></ng-content>
      </main>
    </div>
  `,
  styleUrls: ['navigation-rail.component.scss'],
})
export class NavigationRailComponent implements OnInit {

  @ViewChild('content') content;
  @Input() path: string;

  loggedIn$: Observable<boolean>;
  admin$: Observable<boolean>;

  constructor(
    private router: Router,
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {
    this.loggedIn$ = this.store.pipe(
      select(getAuthUser),
      map(user => Boolean(user))
    );
    this.admin$ = this.store.pipe(
      select(getAuthUser),
      map(user => user ? isAdmin(user.email) : false)
    );
  }

  showPage(pageName: string): void {
    this.content.nativeElement.scroll(0, 0);
    this.store.dispatch(navClicked({pageName}));
    this.router.navigate([`/${pageName}`]);
  }
}
