import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, map, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { goToProfile, saveProfile } from './profile.actions';
import { getPaidSelector, getProfileSelector } from './profile.selector';
import { select, Store } from '@ngrx/store';
import { getTaxDocumentsForm, getTaxProfileForm } from '../../form/store/form.selector';
import { State } from '../../reducers';
import { TaxDocumentsForm, TaxProfileForm } from '../../form/model/form.model';
import { getAuthUser } from '../../authentication/store/authentication.selector';
import { User } from '../../authentication/model/user.model';
import {
  getUserAfterSignInSuccess,
  getUserAfterSignUpSuccess,
} from '../../authentication/store/authentication.actions';
import { refreshProfileSuccess } from '../../datastore/store/datastore.actions';
import { taxValidated } from '../../documents/store/documents.actions';
import { Profile } from '../model/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<State>
  ) {
  }

  showProfileEffect$ = createEffect(() => this.actions$.pipe(
    ofType(
      goToProfile.type,
      getUserAfterSignUpSuccess.type,
      getUserAfterSignInSuccess.type,
      refreshProfileSuccess.type
    ),
    combineLatest(
      this.store.pipe(select(getTaxProfileForm)),
      this.store.pipe(select(getAuthUser)),
      this.store.pipe(select(getPaidSelector)),
      this.store.pipe(select(getTaxDocumentsForm))
    ),
    tap(([, taxProfileForm, user, hasPaid, taxDocumentForm]:
           [any, TaxProfileForm, User, boolean, TaxDocumentsForm]) => {
      if (taxProfileForm == null) {
        this.router.navigate(['/tax-profile']);
      } else if (user == null) {
        this.router.navigate(['/signin']);
      } else if (!hasPaid) {
        this.router.navigate(['/pay']);
      } else if (taxDocumentForm == null) {
        this.router.navigate(['/form']);
      } else {
        this.router.navigate(['/profile']);
      }
    }),
  ), {dispatch: false});

  saveAfterValidationEffect$ = createEffect(() => this.actions$.pipe(
    ofType(taxValidated.type),
    withLatestFrom(this.store.pipe(select(getProfileSelector))),
    map(([, profile]: [any, Profile]) => saveProfile({profile}))
  ));

}
