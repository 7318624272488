import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-messages',
  template: `
    <div class="cui-embed"
         style="height: 100%; width: 100%;"
         data-cui-uid="HHkKSWmi"
         data-cui-avatar="https://images.typeform.com/images/YbfD39KUaTxv"
         data-cui-mode="widget"
         data-cui-pill-button-color="#A8293C"
         data-cui-transparency="100">
    </div>
  `,
  styles: [`
    :host {
      display: block;
      height: 100%;
      background-color: #F2F2F2;
    }
  `]

})
export class MessagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
