import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { signIn } from '../store/authentication.actions';
import { Subscription } from 'rxjs';
import { getError } from '../store/authentication.selector';

@Component({
  selector: 'leaf-signin',
  template: `
    <form>
      <h3>Connexion</h3>
      <p *ngIf="messageError" class="error-msg">{{ messageError }}</p>
      <mat-form-field [formGroup]="signInForm">
        <mat-label>Adresse e-mail</mat-label>
        <input matInput type="email"
               formControlName="email">
      </mat-form-field>
      <mat-form-field [formGroup]="signInForm">
        <mat-label>Mot de passe</mat-label>
        <input matInput type="password"
               formControlName="password">
      </mat-form-field>
      <button mat-flat-button
              color="primary"
              type="submit"
              (click)="formSubmit()"
              [disabled]="signInForm.invalid">SE CONNECTER
      </button>
      <p><a routerLink="/forgot-password">Mot de passe oublié ?</a></p>
      <mat-divider></mat-divider>
      <p>Vous n'avez pas encore de compte ?</p>
      <a routerLink="/signup">Créer un compte</a>
    </form>
  `,
  styleUrls: ['./../common/authentication.common.scss']
})
export class SignInComponent implements OnInit, OnDestroy {

  signInForm: FormGroup;
  messageError: string;
  errorSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.errorSubscription = this.store.pipe(select(getError)).subscribe(error => {
      if (error) {
        this.messageError = this.getMessageError(error);
      } else {
        this.messageError = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.errorSubscription.unsubscribe();
  }

  formSubmit() {
    this.store.dispatch(signIn({
      email: this.signInForm.value.email,
      password: this.signInForm.value.password
    }));
  }

  getMessageError(error: any) {
    switch (error.code) {
      case 'auth/invalid-email':
        return 'L\'adresse mail n\'est pas valide';
      case 'auth/user-not-found':
        return 'L\'adresse mail n\'est pas reconnue';
      case 'auth/wrong-password':
        return 'Le mot de passe n\'est pas valide';
      default:
        console.error(error);
    }
    return error.message;
  }
}
