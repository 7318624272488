import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { TaxFormSummary } from '../model/tax-form-summary.model';
import { environment } from '../../../environments/environment';
import { User } from '../../authentication/model/user.model';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private functions: AngularFireFunctions
  ) {
  }

  getUserStatuses(): Observable<TaxFormSummary[]> {
    const charge = this.functions.httpsCallable<any, TaxFormSummary[]>(environment.admin.statusFunction);
    return charge({});
  }

  getUser(userId: string): Observable<User> {
    const charge = this.functions.httpsCallable<any, User>(environment.admin.userFunction);
    return charge(userId);
  }
}
