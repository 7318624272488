import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-testimonial',
  template: `
    <div class="testimonials">
      <h1>Nos clients vous disent ce qu’ils en pensent</h1>
      <div fxLayout="column" fxLayoutAlign="center center"
           fxLayout.gt-md="row" fxLayoutAlign.gt-md="center top"
           class="testimonials-content">
        <div class="testimonial">
          <span>Entreprise très sérieuse, fiable et efficace.
            J’y fais faire ma déclaration d’impôts depuis plusieurs années déjà et tout s’est toujours très bien passé.
            Le personnel est gentil et consciencieux.<br />Je recommande vivement !</span>
          <h2>Nathalie B.</h2>
        </div>
        <div class="testimonial">
          <span>Les nouveaux locaux sont justes magnifiques, sans parler de la nouvelle équipe qui est très dynamique et motivée.<br />
            Je suis très satisfait des services qu’ils mettent à disposition et de leur professionnalisme !</span>
          <h2>Nicolas B.</h2>
        </div>
        <div class="testimonial">
          <span>Professionnel, avisé et transparent, je suis ravi du service qu’ils m’apportent.</span>
          <h2>Mathias G.</h2>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
