import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { ChecklistSimpleComponent } from './question/checklist-simple/checklist-simple.component';
import { AmountSimpleComponent } from './question/amount-simple/amount-simple.component';
import { ChoiceSimpleComponent } from './question/choice-simple/choice-simple.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { YesOrNoSimpleComponent } from './question/yes-or-no-simple/yes-or-no-simple.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TaxProfileFormComponent } from './tax-profile-form/tax-profile-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { TaxDocumentsFormComponent } from './tax-documents-form/tax-documents-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { PayComponent } from '../payment/pay.component';
import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { OverviewComponent } from './tax-documents-form/overview/overview.component';
import { RouterModule } from '@angular/router';
import { TaxProfileComponent } from './tax-profile/tax-profile.component';
import { ContentModule } from '../content/content.module';


@NgModule({
  declarations: [
    AmountSimpleComponent,
    ChoiceSimpleComponent,
    ChecklistSimpleComponent,
    PayComponent,
    YesOrNoSimpleComponent,
    TaxProfileComponent,
    TaxProfileFormComponent,
    TaxDocumentsFormComponent,
    OverviewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSliderModule,
    MatRadioModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatStepperModule,
    FlexLayoutModule,
    NgxStripeModule,
    RouterModule,
    ContentModule,
  ],
  exports: [
    TaxProfileComponent,
    TaxProfileFormComponent,
    OverviewComponent,
  ]
})
export class FormModule {
}
