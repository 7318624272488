import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import FormState from './form.reducer';

export const getFormState = (state: State) => state.form;

export const getTaxProfileForm = createSelector(
  getFormState,
  (state: FormState) => state.taxProfileForm
);

export const getTaxDocumentsForm = createSelector(
  getFormState,
  (state: FormState) => state.taxDocumentsForm
);

export const getTaxForms = createSelector(
  getFormState,
  (state: FormState) => [state.taxProfileForm, state.taxDocumentsForm]
);
