import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../model/user.model';
import {
  forgotPassword,
  forgotPasswordError,
  forgotPasswordSuccess,
  getUserAfterSignInSuccess,
  getUserAfterSignUpSuccess,
  getUserSuccess,
  signIn,
  signInError,
  signOutSuccess,
  signUp,
  signUpError
} from './authentication.actions';

export default interface AuthenticationState {
  user: User;
  resetPasswordSent: boolean;
  error: any;
}

export const initialState: AuthenticationState = {
  user: null,
  resetPasswordSent: false,
  error: null,
};

const authenticationReducer = createReducer(
  initialState,
  on(
    getUserSuccess,
    getUserAfterSignUpSuccess,
    getUserAfterSignInSuccess,
    (state, action) => (
      {...state, user: action.user}
    )),
  on(
    signIn,
    (state) => (
      {...state, error: null}
    )),
  on(
    signInError,
    (state, action) => (
      {...state, error: action.error}
    )),
  on(
    signUp,
    (state) => (
      {...state, error: null}
    )),
  on(
    signUpError,
    (state, action) => (
      {...state, error: action.error}
    )),
  on(
    forgotPassword,
    (state) => (
      {...state, error: null, resetPasswordSent: false}
    )),
  on(
    forgotPasswordSuccess,
    (state) => (
      {...state, resetPasswordSent: true}
    )),
  on(
    forgotPasswordError,
    (state, action) => (
      {...state, error: action.error}
    )),
  on(
    signOutSuccess,
    (state) => (
      {...state, user: null, resetPasswordSent: false, error: null}
    ))
  )
;

export function reducer(state: AuthenticationState | undefined, action: Action) {
  return authenticationReducer(state, action);
}
