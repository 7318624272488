import { Component, OnInit } from '@angular/core';
import { User } from '../../authentication/model/user.model';
import { Document } from '../../documents/model/documents.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getSelectedUser } from '../store/admin.actions';
import { getSelectedUserSelector } from '../store/admin.selector';

@Component({
  selector: 'leaf-admin-documents',
  template: `
    <div *ngIf="(user$ | async) as user" class="container">
      <h3>Documents de {{ user.email }}</h3>
      <leaf-list [userId]="user.userId"
                 [columnsToDisplay]="columnsToDisplay"
                 [columnsNames]="columnsNames"
                 [documents]="documents"></leaf-list>
    </div>
  `,
  styles: [
  ]
})
export class AdminDocumentsComponent implements OnInit {

  user$: Observable<User>;
  documents: Document[];

  columnsToDisplay: string[] = ['name', 'status', 'lastChange'];
  columnsNames: string[] = ['Nom', 'Statut', 'Derniers changements'];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) {
    this.documents = [];
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      take(1)
    ).subscribe(params => {
      console.log(params);
      console.log(params.get('userId'));
      this.store.dispatch(getSelectedUser({userId: params.get('userId')}));
    });
    this.user$ = this.store.pipe(select(getSelectedUserSelector));
  }

}
