import { Routes } from '@angular/router';
import { SignInComponent } from './authentication/signin/sign-in.component';
import { SignUpComponent } from './authentication/signup/sign-up.component';
import { ForgotPasswordComponent } from './authentication/forgotpassword/forgot-password.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { ChatComponent } from './chat/chat.component';
import { DocumentsComponent } from './documents/documents.component';
import { TaxHomeComponent } from './tax-home/tax-home.component';
import { TaxDocumentsFormComponent } from './form/tax-documents-form/tax-documents-form.component';
import { ActivationComponent } from './activation/activation.component';
import { TermsComponent } from './content/legal/terms.component';
import { PayComponent } from './payment/pay.component';
import { TaxProfileFormComponent } from './form/tax-profile-form/tax-profile-form.component';
import { PaymentGuard } from './payment/payment.guard';
import { ProfileComponent } from './profile/profile.component';
import { UserStatusComponent } from './admin/user-status.component';
import { AdminGuard } from './admin/admin.guard';
import { AdminDocumentsComponent } from './admin/documents/admin-documents.component';

export const routes: Routes = [
  {path: '', component: TaxHomeComponent},
  {path: 'tax-profile', component: TaxProfileFormComponent},
  {path: 'activation', component: ActivationComponent},
  {path: 'signin', component: SignInComponent},
  {path: 'signup', component: SignUpComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {path: 'pay', component: PayComponent},
      {
        path: '',
        canActivate: [PaymentGuard],
        children: [
          {path: 'documents', component: DocumentsComponent},
          {path: 'form', component: TaxDocumentsFormComponent},
          {path: 'messages', component: ChatComponent},
          {path: 'profile', component: ProfileComponent},
          {
            path: 'admin',
            canActivate: [AdminGuard],
            children: [
              {path: '', component: UserStatusComponent},
              {path: 'documents/:userId', component: AdminDocumentsComponent},
            ]
          },
        ]
      }
    ]
  },
  {path: 'terms', component: TermsComponent},
  {path: '**', component: TaxHomeComponent},
];
