import { FormSection, Question } from './form.model';
import { Validators } from '@angular/forms';

export default [
  {
    title: 'Situation',
    questions: [
      {
        type: 'amount',
        title: 'Pour quelle année fiscale souhaitez-vous effectuer la déclaration d\'impôt ?',
        controlName: 'taxYear',
        initialValue: 2020,
        validators: Validators.required,
      } as Question,
      {
        type: 'yes-or-no',
        title: 'Durant cette année fiscale, étiez-vous marié ou en partenariat (pax) ?',
        controlName: 'marriedQuestion',
        validators: Validators.required,
      } as Question,
      {
        type: 'checklist',
        title: 'Avez-vous été ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'activities',
        options: [
          'employee',
          'unemployed',
          'independent',
          'retired'
        ],
      } as Question,
      {
        type: 'checklist',
        title: 'Avez-vous perçu d\'autres types de revenus ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'otherIncome',
        options: [
          'rentAssistance',
          'healthInsuranceReimbursements',
          'lotteryWinnings',
        ],
      } as Question,
    ]
  } as FormSection,
  {
    title: 'Dépenses',
    questions: [
      {
        type: 'yes-or-no',
        title: 'Avez-vous payé des acomptes ?',
        controlName: 'advancePayments',
        validators: Validators.required,
      } as Question,
      {
        type: 'checklist',
        title: 'Avez-vous eu ce type de frais ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'costs',
        options: [
          'medicalAndDentalExpenses',
          'training',
          'donations',
          'unionDues',
        ],
      } as Question,
      {
        type: 'checklist',
        title: 'Avez-vous eu des dépenses liés à votre patrimoine ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'expenditurePatrimony',
        options: [
          'thirdPillarA',
          'thirdPillarB',
          'contributionsLPP',
          'investment',
          'stakesInCompanies',
          'loans',
        ],
      } as Question,
      {
        type: 'checklist',
        title: 'Avez-vous eu des dettes ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'taxDebts',
        options: [
          'creditCardOrLoanDebts',
          'mortgageDebts',
          'borrowingFromAThirdParty',
          'taxDebtsStillOutstanding',
        ],
      } as Question,
    ]
  } as FormSection,
  {
    title: 'Immobilier',
    questions: [
      {
        type: 'yes-or-no',
        title: 'Avez-vous loué un de vos biens immobiliers ?',
        controlName: 'rent',
        validators: Validators.required,
      } as Question,
      {
        type: 'yes-or-no',
        title: 'Avez-vous une hypothèque ?',
        controlName: 'mortgage',
        validators: Validators.required,
      } as Question,
      {
        type: 'yes-or-no',
        title: 'Avez-vous des biens certifiés Minergie ?',
        controlName: 'minergie',
        validators: Validators.required,
      } as Question,
      {
        type: 'yes-or-no',
        title: 'Avez-vous acheté ou vendu un bien immobilier ?',
        controlName: 'propertyPurchase',
        validators: Validators.required,
      } as Question,
    ]
  } as FormSection,
  {
    title: 'Famille',
    questions: [
      {
        type: 'checklist',
        title: 'Avez-vous perçu ces types de revenus ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'familyIncome',
        options: [
          'familyAllowances',
          'childSupportIncome',
        ],
      } as Question,
      {
        type: 'checklist',
        title: 'Avez-vous eu des dépenses ?',
        hint: 'Cochez tout ce qui s\'applique',
        controlName: 'familyExpenses',
        options: [
          'childSupportExpense',
          'childcareCosts',
        ],
      } as Question,
    ]
  } as FormSection,
] as FormSection[];
