import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getTaxDocumentsForm, getTaxProfileForm } from '../form/store/form.selector';
import { State } from '../reducers';
import { Observable } from 'rxjs';
import { TaxDocumentsForm, TaxProfileForm } from '../form/model/form.model';
import { getPrice } from '../activation/store/activation.selector';
import { getPaidSelector } from '../profile/store/profile.selector';
import { getAuthUser } from '../authentication/store/authentication.selector';
import { User } from '../authentication/model/user.model';
import { Router } from '@angular/router';
import { callToActionClicked } from './store/tax-home.actions';

@Component({
  selector: 'leaf-tax-home',
  template: `
    <div class="main" fxLayout="column">
      <div class="spotlight" fxLayout="column" fxLayout.gt-sm="row">
        <img fxFlex.gt-sm="50" src="assets/img/tax/mec-qui-sourit-ipad.jpg" alt="Client satisfait devant son iPad"/>
        <div fxFlex.gt-sm="50" class="tax-form">
          <div class="heading">
            <h1 style="font-weight: bold">On s’occupe de votre <span class="theme-color">déclaration d’impôts ?</span></h1>
            <h2>Optimisez vos impôts sans effort, Nos experts s’occupent de tout.</h2>
          </div>
          <div *ngIf="(taxProfileForm$ | async) === null; else receivedOffer" class="navigation">
            <p>Répondez à 5 questions simples pour recevoir une offre personnalisée</p>
            <leaf-tax-profile-form></leaf-tax-profile-form>
          </div>
          <ng-template #receivedOffer>
            <div *ngIf="(paid$ | async) === false; else hasPaid" class="navigation">
              <p>Nous vous avons proposé une offre personnalisée au prix de CHF {{ price$ | async }}.―</p>
              <button mat-flat-button color="primary" routerLink="/activation">Accéder à l'offre</button>
            </div>
            <ng-template #hasPaid>
              <div *ngIf="(user$ | async) === null; else connected" class="navigation">
                <p>Veuillez vous connecter afin d'accéder à votre dashboard</p>
                <button mat-flat-button color="primary" routerLink="/signin">Se connecter</button>
              </div>
              <ng-template #connected>
                <div *ngIf="(taxDocumentForm$ | async) === null; else dashboard" class="navigation">
                  <p>Complétez le formulaire afin d'identifier les documents que vous devez nous fournir pour le traitement de votre déclaration fiscale.</p>
                  <button mat-flat-button color="primary" routerLink="/form">Compléter le formulaire</button>
                </div>
                <ng-template #dashboard>
                  <div class="navigation">
                    <p>Accédez au dashboard pour consulter votre profil, communiquer avec un de nos experts ou importer vos documents.</p>
                    <button mat-flat-button color="primary" routerLink="/profile">Accéder au dashboard</button>
                  </div>
                </ng-template>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="why">
        <h3>Pourquoi nous faire confiance pour votre <span class="theme-color">déclaration fiscale ?</span></h3>
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center center" class="items">
          <leaf-why-item imagePath="assets/img/tax/coffee.png"
                         title="0 %"
                         text="stress, avec LEAF déclarez zen"></leaf-why-item>
          <leaf-why-item imagePath="assets/img/tax/star.png"
                         title="10 ans"
                         text="d’expérience sur le marché Suisse"></leaf-why-item>
          <leaf-why-item imagePath="assets/img/tax/heart.png"
                         title="600"
                         text="contribuables nous font confiance"></leaf-why-item>
          <leaf-why-item imagePath="assets/img/tax/leaf.png"
                         title="0 %"
                         text="papier gaspillé !"></leaf-why-item>
        </div>
      </div>
      <div class="motion-design">
        <leaf-motion-design></leaf-motion-design>
        <button mat-flat-button color="primary" (click)="startProfileForm('motion-design')">Calculer le prix de la prestation</button>
      </div>
      <leaf-partners></leaf-partners>
      <leaf-testimonial></leaf-testimonial>
      <leaf-how></leaf-how>
      <leaf-faq></leaf-faq>
      <button mat-flat-button color="primary" (click)="startProfileForm('last')">Calculer le prix de la prestation</button>
    </div>
    <leaf-footer></leaf-footer>
  `,
  styleUrls: ['tax-home.component.scss']
})
export class TaxHomeComponent implements OnInit {

  paid$: Observable<boolean>;
  price$: Observable<number>;
  taxDocumentForm$: Observable<TaxDocumentsForm>;
  taxProfileForm$: Observable<TaxProfileForm>;
  user$: Observable<User>;

  constructor(
    private store: Store<State>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.paid$ = this.store.pipe(select(getPaidSelector));
    this.price$ = this.store.pipe(select(getPrice));
    this.taxDocumentForm$ = this.store.pipe(select(getTaxDocumentsForm));
    this.taxProfileForm$ = this.store.pipe(select(getTaxProfileForm));
    this.user$ = this.store.pipe(select(getAuthUser));
  }

  startProfileForm(buttonId: string) {
    this.store.dispatch(callToActionClicked({buttonId}));
    this.router.navigate(['/tax-profile']);
  }
}
