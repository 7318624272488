import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ActivityComponent } from './activity/activity.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { TypePipeModule } from '../shared/type.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatSortModule } from '@angular/material/sort';
import { DragAndDropDirective } from './directive/drag-and-drop.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ListComponent } from './list/list.component';


@NgModule({
  declarations: [
    DocumentsComponent,
    ActivityComponent,
    DragAndDropDirective,
    ListComponent,
  ],
  exports: [
    ActivityComponent,
    ListComponent,
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MaterialFileInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    MatTableModule,
    TypePipeModule,
    ReactiveFormsModule,
    MatSortModule,
    FlexLayoutModule,
    MatMenuModule,
    MatTooltipModule,
  ]
})
export class DocumentsModule { }
