import { QuestionAndAnswer } from '../../../activation/model/activation.model';

export default [
  {
    question: 'Qui est derrière TAX by LEAF ?',
    answer: 'TAX by LEAF a été conçu par une équipe de fiscalistes, d’agents fiduciaires et d’ingénieurs afin de ' +
      'délivrer le meilleur de la fiscalité et la technologie.'
  },
  {
    question: 'Qu’est-ce que LEAF ?',
    answer: 'LEAF est un service fiscal en ligne qui vous permet de déléguer l’établissement de votre déclaration ' +
      'd’impôt à des fiscalistes afin d’optimiser vos impôts.'
  },
  {
    question: 'Où est basée la société LEAF?',
    answer: 'Le siège social de LEAF se trouve à Genève, nous avons des locaux également à Lausanne.\n' +
      'Nous sommes enregistrés au registre du commerce de Genève, sous le numéro d’entreprise CHE-343.757.840, à la Rue ' +
      'de l’Arquebuse 10, 1204 Genève, Suisse.'
  },
  {
    question: 'Comment cela fonctionne-t’il ?',
    answer: 'L’expérience complète de TAX by LEAF est disponible sur notre site internet et sur mobile.\n' +
      'Avec LEAF, il n’a jamais été aussi facile de compléter sa déclaration d’impôt même si vous n’avez aucune compétence. \n' +
      'En répondant simplement à quelques questions, nous vous indiquerons quels documents nous avons besoin pour ' +
      'établir votre déclaration d’impôt.\n' +
      'Puis nos fiscalistes s’occupent de vos obligations fiscales en toute simplicité, de l’établissement jusqu’à ' +
      'l’envoi de votre déclaration fiscale aux administrations. \n' +
      'Vous n’avez plus besoin de vous inquiéter et vous pouvez à tout moment retrouver vos informations en ligne.\n' +
      'Une fois terminé, vous aurez la possibilité de l’envoyer sous format numérique ou de la recevoir par la poste.'
  },
  {
    question: 'Combien ça coûte ?',
    answer: 'Étant donné que nous avons digitalisé une partie de nos processus, nous pouvons vous proposer nos services ' +
      'à des tarifs très attractifs. En répondant simplement à quelques questions, nous vous indiquerons à quel tarif ' +
      'nous établirons votre déclaration d’impôt.'
  },
  {
    question: 'Qu’est-ce qu’il se passe si je trouve une erreur dans ma déclaration de revenus ?',
    answer: 'Pas de problème, connectez-vous simplement sur votre compte et faites nous part de vos remarques. Nous ' +
      'apporterons les modifications nécessaires et nous vous renverrons immédiatemment une nouvelle version corrigée.'
  },
  {
    question: 'Quels cantons sont couverts par TAX by LEAF ?',
    answer: 'Pour le moment, notre service est valable en Suisse romande dans les cantons suivants: ' +
      'Fribourg, Genève, Neuchâtel, Vaud et Valais.'
  },
  {
    question: 'Est-ce que mes informations sont confidentielles ?',
    answer: 'Absolument, nous respectons votre vie privée (voir notre politique de confidentialité). Nous ne partageons ' +
      'pas et ne vendons vos informations à une tierce partie pour n’importe quel motif.'
  },
  {
    question: 'Qui a accès à mes données ?',
    answer: 'La sécurité des données est notre priorité n°1, vous seul pouvez voir vos données. Toutes les données et ' +
      'reçus téléchargés sont stockés et chiffrés à tout moment. Vos données ne seront traitées que via une connexion ' +
      'internet sécurisée.'
  },
  {
    question: 'Puis-je supprimer mon compte et mes données ?',
    answer: 'Oui, vous pouvez supprimer totalement vos données fiscales depuis votre compte utilisateur à tout moment. ' +
      'Notez que dans ce cas, il n’est plus possible de restaurer vos données.\n' +
      'Pour en savoir plus sur la protection des données et de la vie privée, contactez-nous.\n'
  },
] as QuestionAndAnswer[];
