export default {
  employee: 'Salarié',
  unemployed: 'Au chômage',
  independent: 'Indépendant',
  retired: 'Retraité',
  rentAssistance: 'Aide au loyer',
  healthInsuranceReimbursements: 'Subside d\'assurance-maladie',
  lotteryWinnings: 'Gains de loterie',
  medicalAndDentalExpenses: 'Frais médicaux et dentaires à votre charge',
  training: 'Formation',
  donations: 'Dons',
  unionDues: 'Cotisation syndicale',
  thirdPillarA: '3ème pilier a - prévoyance individuelle liée',
  thirdPillarB: '3ème pilier b - prévoyance individuelle libre',
  contributionsLPP: 'Rachat de cotisations LPP (2ème pillier)',
  investment: 'Investissements (actions, obligations, fonds de placements, etc.)',
  stakesInCompanies: 'Participations dans des sociétés',
  loans: 'Prêts octroyés à une tierce personne',
  creditCardOrLoanDebts: 'Carte de crédit ou emprunt',
  mortgageDebts: 'Hypothécaires',
  borrowingFromAThirdParty: 'Emprunt auprès d\'une tierce personne',
  taxDebtsStillOutstanding: 'Dettes fiscales encore ouvertes à la fin de l\'année fiscale',
  familyAllowances: 'Allocations familiales',
  childSupportIncome: 'Pensions alimentaires',
  childSupportExpense: 'Pensions alimentaires',
  childcareCosts: 'Frais de garde',
};
