import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailChimpService {

  SERVER_URL = 'https://swiss.us4.list-manage.com/subscribe/post-json';

  constructor(
    public httpClient: HttpClient
  ) {
  }

  save(email) {
    const params = new HttpParams()
      .set('u', '196e80479ff95e776eaf02650')
      .set('id', '5c270355b9')
      .set('EMAIL', email)
      .set('b_196e80479ff95e776eaf02650_5c270355b9', '');

    this.httpClient.jsonp<any>(this.SERVER_URL + '?' + params.toString(), 'c');
  }
}
