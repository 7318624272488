import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
  forgotPassword,
  forgotPasswordError,
  forgotPasswordSuccess,
  getUser,
  getUserAfterSignInSuccess,
  getUserAfterSignUpSuccess,
  getUserFailure,
  getUserSuccess,
  signIn,
  signInError,
  signInSuccess,
  signOut,
  signOutSuccess,
  signUp,
  signUpError,
  signUpSuccess
} from './authentication.actions';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationEffects {

  constructor(
    private authenticationService: AuthenticationService,
    private actions$: Actions,
    private router: Router,
  ) {
  }

  /* SIGN UP */

  signUpEffect$ = createEffect(() => this.actions$.pipe(
    ofType(signUp.type),
    mergeMap((action: ReturnType<typeof signUp>) =>
      this.authenticationService.signUp(action.email, action.password).pipe(
        map(() => signUpSuccess()),
        catchError(error => of(signUpError({error})))
      ))
  ));

  getUserAfterSignUpEffect$ = createEffect(() => this.actions$.pipe(
    ofType(signUpSuccess.type),
    mergeMap(() => this.authenticationService.getCurrentUser()),
    map((user) => getUserAfterSignUpSuccess({user}))
  ));

  /* SIGN IN */

  signInEffect$ = createEffect(() => this.actions$.pipe(
    ofType(signIn.type),
    mergeMap((action: ReturnType<typeof signIn>) =>
      this.authenticationService.signIn(action.email, action.password).pipe(
        map(() => signInSuccess()),
        catchError(error => of(signInError({error})))
      ))
  ));

  getUserAfterSignInEffect$ = createEffect(() => this.actions$.pipe(
    ofType(signInSuccess.type),
    mergeMap(() => this.authenticationService.getCurrentUser()),
    map((user) => getUserAfterSignInSuccess({user}))
  ));

  /* GET USER */

  getUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getUser.type),
    mergeMap(() =>
      this.authenticationService.getCurrentUser().pipe(
        map((user) => getUserSuccess({user})),
        catchError(() => of(getUserFailure()))
      )
    ),
  ));

  /* SIGN OUT */

  signOutEffect$ = createEffect(() => this.actions$.pipe(
    ofType(signOut.type),
    mergeMap(() => this.authenticationService.signOut()),
    map(() =>  signOutSuccess())
  ));

  redirectToRootEffect$ = createEffect(() => this.actions$.pipe(
    ofType(signOutSuccess.type),
    tap(() => this.router.navigate(['/']))
  ), {dispatch: false});

  /* FORGOT PASSWORD */

  forgotPasswordEffect$ = createEffect(() => this.actions$.pipe(
    ofType(forgotPassword.type),
    mergeMap((action: ReturnType<typeof forgotPassword>) => this.authenticationService.forgotPassword(action.email).pipe(
      map((val, err) => forgotPasswordSuccess()),
      catchError(error => {
        if (error?.code === 'auth/user-not-found') {
          return of(forgotPasswordSuccess());
        } else {
          return of(forgotPasswordError({error}));
        }
      })
    ))
  ));

}
