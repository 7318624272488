import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnavailableService } from './model/unavailable-service.model';
import { subscribeToNewsletter } from '../shared/store/newsletter.actions';

@Component({
  selector: 'leaf-unavailable-service',
  template: `
    <div class="unavailable-service-modal">
      <div class="logo">
        <img src="assets/img/logo-horizontal.png" alt="LEAF Technology"/>
      </div>
      <form [formGroup]="newsletterForm">
        <h3>{{ data.titre }}</h3>
        <p>{{ data.content }}</p>
        <mat-form-field *ngIf="showFormFields">
          <mat-label>Votre adresse e-mail</mat-label>
          <input matInput formControlName="email" type="email">
        </mat-form-field>
        <button *ngIf="showFormFields"
                mat-flat-button
                color="primary"
                type="submit"
                [disabled]="newsletterForm.invalid"
                (click)="formSubmit()">VALIDER
        </button>
      </form>
      <button class="close-button" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
  `,
  styles: [`
    .unavailable-service-modal {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .close-button {
      position: absolute;
      top: -16px;
      right: -16px;
    }

    .logo {
      flex: 1 1 33%;
    }

    img {
      width: 100%;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 2 1 66%;
      padding-left: 32px;
    }

    h3 {
      padding-right: 16px;
    }
  `]
})
export class UnavailableServiceComponent implements OnInit {

  newsletterForm: FormGroup;
  showFormFields: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UnavailableService,
    public dialogRef: MatDialogRef<UnavailableServiceComponent>,
    private store: Store<State>,
    private formBuilder: FormBuilder,
  ) {
    this.showFormFields = true;
  }

  ngOnInit(): void {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  formSubmit() {
    this.data.titre = 'Votre adresse email a bien été enregistrée';
    this.data.content = 'Vous pouvez prendre contacte avec nous si nécessaire à l\'adresse mail: info@leaf.swiss';
    this.showFormFields = false;
    this.store.dispatch(subscribeToNewsletter({
      email: this.newsletterForm.value.email,
    }));
  }

  close() {
    this.dialogRef.close('Pizza!');
  }
}
