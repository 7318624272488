import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStatusComponent } from './user-status.component';
import { UserSummaryComponent } from './user-summary.component';
import { TypePipeModule } from '../shared/type.module';
import { RouterModule } from '@angular/router';
import { AdminDocumentsComponent } from './documents/admin-documents.component';
import { DocumentsModule } from '../documents/documents.module';


@NgModule({
  declarations: [
    UserStatusComponent,
    UserSummaryComponent,
    AdminDocumentsComponent,
  ],
  imports: [
    CommonModule,
    TypePipeModule,
    RouterModule,
    DocumentsModule,
  ],
  exports: [
    UserStatusComponent
  ]
})
export class AdminModule { }
