import { ValidatorFn } from '@angular/forms';

export class TaxProfileForm {
  residencyInput: string = null;
  nationalityInput: boolean = null;
  childrenInput: boolean = null;
  propertyOwnerInput: boolean = null;
  propertyNumberInput: number = null;
  investmentsInput: string = null;
}

export class TaxDocumentsForm {
  activities: string[] = [];
  advancePayments: boolean = null;
  costs: string[] = [];
  expenditurePatrimony: string[] = [];
  familyExpenses: string[] = [];
  familyIncome: string[] = [];
  marriedQuestion: boolean = null;
  minergie: boolean = null;
  mortgage: boolean = null;
  otherIncome: string[] = [];
  propertyPurchase: boolean = null;
  rent: boolean = null;
  taxDebts: string[] = [];
  taxYear = 2020;
}

export interface FormSection {
  title: string;
  questions: Question[];
}

export interface Question {
  type: string;
  title: string;
  hint: string;
  label: string;
  options: string[];
  initialValue: any;
  controlName: string;
  validators: ValidatorFn | ValidatorFn[];
}
