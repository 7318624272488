import { createAction, props } from '@ngrx/store';
import { User } from '../model/user.model';

export const missingDocumentClick = createAction(
  '[Authentication] missing document click'
);

export const signUp = createAction(
  '[Authentication] sign up',
  props<{ email: string, password: string }>()
);

export const signUpSuccess = createAction(
  '[Authentication] sign up success'
);

export const signUpError = createAction(
  '[Authentication] sign up error',
  props<{ error: any }>()
);

export const signIn = createAction(
  '[Authentication] sign in',
  props<{ email: string, password: string }>()
);

export const signInSuccess = createAction(
  '[Authentication] sign in success'
);

export const signInError = createAction(
  '[Authentication] sign in error',
  props<{ error: any }>()
);

export const forgotPassword = createAction(
  '[Authentication] forgot password',
  props<{ email: string }>()
);

export const forgotPasswordSuccess = createAction(
  '[Authentication] forgot password success'
);

export const forgotPasswordError = createAction(
  '[Authentication] forgot password error',
  props<{ error: any }>()
);

export const getUser = createAction(
  '[Authentication] get user'
);

export const getUserSuccess = createAction(
  '[Authentication] get user success',
  props<{ user: User }>()
);

export const getUserAfterSignInSuccess = createAction(
  '[Authentication] get user after sign in success',
  props<{ user: User }>()
);

export const getUserFailure = createAction(
  '[Authentication] get user failure'
);

export const getUserAfterSignUpSuccess = createAction(
  '[Authentication] get user after sign up success',
  props<{ user: User }>()
);

export const signOut = createAction(
  '[Authentication] sign out'
);

export const signOutSuccess = createAction(
  '[Authentication] sign out success'
);
