export const environment = {
  production: false,
  appName: 'LEAF-dev',
  enableAnalytics: false,
  firebase: {
    apiKey: 'AIzaSyDw8J-A6shYo2FIm6REPht181B17cIusfI',
    authDomain: 'myleaf-4l1v3.firebaseapp.com',
    databaseURL: 'https://myleaf-4l1v3.firebaseio.com',
    projectId: 'myleaf-4l1v3',
    storageBucket: 'myleaf-4l1v3.appspot.com',
    messagingSenderId: '116019718162',
    appId: '1:116019718162:web:e5267360c8d4882f7a10cb',
    measurementId: 'G-E72Q3010V4'
  },
  datastore: {
    documents: 'documents-dev',
    evaluation: 'evaluation-dev',
    importedDoc: 'importedDoc-dev',
    observation: 'observation-dev',
    profile: 'profile-dev',
    taxProfile: 'taxProfile-dev',
    taxDocuments: 'taxDocuments-dev'
  },
  storage: {
    path: 'tax-dev'
  },
  stripe: {
    chargeFunction: 'chargeDev',
    publishableKey: 'pk_test_51HxreVGK8Jpmd7j7Y1t0m31YKJyPRkYcV5GqPqOMxuBZnzwbrQRnRa5gx6eRep1NUBYyDe9ZzQttFhV5pt70dAp000AkANdFXB'
  },
  admin: {
    statusFunction: 'userStatusDev',
    userFunction: 'userDev'
  }
};
