import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question } from '../../model/form.model';

@Component({
  selector: 'leaf-amount-simple',
  template: `
    <h3>{{ question.title }}</h3>
    <span *ngIf="question.hint" class="hint">{{ question.hint }}</span>
    <mat-form-field [formGroup]="parentForm">
      <mat-label>{{ question.label }}</mat-label>
      <input matInput type="text"
             [value]="amount"
             (change)="inputChange($event.target.value)"
             (blur)="onBlur($event.target.value)"
             (focus)="onFocus($event.target.value)"
             [formControlName]="question.controlName">
      <span *ngIf="currency" matPrefix>{{ currency }}&nbsp;</span>
    </mat-form-field>
  `,
  styles: [`
    mat-form-field {
      width: 200px;
    }

    input {
      font-size: 16px;
    }
  `],
  styleUrls: ['./../common/common.question.scss']
})
export class AmountSimpleComponent {
  amount: string;

  @Input() question: Question;
  @Input() parentForm: FormGroup;
  @Input() currency: string;

  @Output() amountChanged = new EventEmitter<number>();

  constructor() {
  }

  inputChange(value) {
    const amount = this.formatNumber(value);
    this.amountChanged.emit(amount);
    this.amount = this.formatAmount(amount?.toString());
  }

  onBlur(value) {
    this.amount = this.formatAmount(value);
  }

  onFocus(value) {
    this.amount = this.unformatAmount(value);
  }

  formatNumber(value: string): number {
    if (value != null && Number(value) >= 0) {
      return Number(value);
    }
    return null;
  }

  formatAmount(value: string): string {
    if (value != null && this.currency != null) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }
    return value;
  }

  unformatAmount(value: string): string {
    if (value != null) {
      return value.replace(/'/g, '');
    }
    return null;
  }
}
