import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { submitTaxProfile } from '../../form/store/form.actions';
import { priceFixed } from './activation.actions';
import { ActivationService } from '../service/activation.service';
import { getTaxProfileDataSuccess } from '../../datastore/store/datastore.actions';

@Injectable({
  providedIn: 'root'
})
export class ActivationEffects {

  constructor(
    private router: Router,
    private actions$: Actions,
    private activationService: ActivationService,
  ) {
  }

  submitTaxProfileEffect$ = createEffect(() => this.actions$.pipe(
    ofType(submitTaxProfile.type, getTaxProfileDataSuccess),
    map((action: ReturnType<typeof submitTaxProfile> | ReturnType<typeof getTaxProfileDataSuccess>) =>
      this.activationService.fixPrice(action.taxProfileForm)
    ),
    map((price: number) => priceFixed({price}))
  ));
}
