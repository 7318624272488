import { NgModule } from '@angular/core';
import { EmptyPipe, IncludesPipe, JoinPipe } from './array.pipe';

@NgModule({
  declarations: [
    EmptyPipe,
    JoinPipe,
    IncludesPipe,
  ],
  imports: [
  ],
  exports: [
    EmptyPipe,
    JoinPipe,
    IncludesPipe,
  ]
})
export class ArrayPipeModule {
}
