import { Injectable } from '@angular/core';
import {
  Element as StripeElement,
  StripeService,
  TokenResult
} from '@nomadreservations/ngx-stripe';
import { Observable } from 'rxjs';
import { Payment } from '../model/payment.model';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private stripe: StripeService,
    private functions: AngularFireFunctions
  ) {
  }

  createToken(element: StripeElement, name: string): Observable<TokenResult> {
    return this.stripe.createToken(element, {name});
  }

  sendPayment(payment: Payment): Observable<any> {
    const charge = this.functions.httpsCallable<Payment, string>(environment.stripe.chargeFunction);
    return charge(payment);
  }
}
