import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import AuthenticationState from './authentication.reducer';

export const getAuthState = (state: State) => state.authentication;

export const getAuthUser = createSelector(
  getAuthState,
  (state: AuthenticationState) => state.user
);

export const getResetPasswordSent = createSelector(
  getAuthState,
  (state: AuthenticationState) => state.resetPasswordSent
);

export const getError = createSelector(
  getAuthState,
  (state: AuthenticationState) => state.error
);
