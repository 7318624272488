import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Activity, Document, ImportedDoc, Observation, Status } from './model/documents.model';
import { select, Store } from '@ngrx/store';
import { State } from '../reducers';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { getActivities, getDocuments, getImportedDocumentsCount } from './store/documents.selector';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadService } from './service/upload.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StorageService } from './service/storage.service';
import { getAuthUser } from '../authentication/store/authentication.selector';
import { User } from '../authentication/model/user.model';
import { MatTableDataSource } from '@angular/material/table';
import {
  observationChanged,
  taxValidated,
  uploadingFileToDocument,
  uploadingFileToDocumentFailure,
  uploadingFileToDocumentSuccess
} from './store/documents.actions';
import { MatSort } from '@angular/material/sort';
import { FileInput } from 'ngx-material-file-input';
import { ValidationComponent } from '../validation/validation.component';
import { MatDialog } from '@angular/material/dialog';
import { getTaxValidatedSelector } from '../profile/store/profile.selector';

@Component({
  selector: 'leaf-documents',
  templateUrl: 'documents.component.html',
  styleUrls: ['documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {

  requiredDocumentsCount: number;
  activities$: Observable<Activity[]>;
  taxValidated$: Observable<boolean>;
  user$: Observable<User>;
  updatedDocumentsCount$: Observable<number>;
  documents: Document[];
  documentsSubscription: Subscription;

  columnsToDisplay: string[] = ['name', 'status', 'lastChange'];
  columnsNames: string[] = ['Nom', 'Statut', 'Derniers changements'];

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(getAuthUser));
    this.activities$ = this.store.pipe(select(getActivities));
    this.updatedDocumentsCount$ = this.store.pipe(select(getImportedDocumentsCount));
    this.taxValidated$ = this.store.pipe(select(getTaxValidatedSelector));
    this.documentsSubscription = this.store.pipe(
      select(getDocuments)
    ).subscribe(documents => {
      if (documents) {
        this.documents = documents;
      }
    });
  }

  ngOnDestroy() {
    this.documentsSubscription.unsubscribe();
  }

  validateTax() {
    this.dialog.open(ValidationComponent, {
      maxWidth: 512,
      data: {
        title: 'Êtes-vous sûr de vouloir valider le traitement de la déclaration ?',
        content: 'Etes-vous sûr d\'avoir bien importé tous les documents demandés ?\n' +
          '\n' +
          'En cliquant sur le bouton confirmer, vous confirmez que votre dossier est complet et vous autorisez LEAF ' +
          'à traiter votre déclaration fiscale. Une situation erronée ou incomplète peut induire des coûts supplémentaires.'
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(taxValidated());
      }
    });
  }

  observationChanged(observation: Observation) {
    this.store.dispatch(observationChanged({observation}));
  }

  uploadingStarted(documentId: string) {
    this.store.dispatch(uploadingFileToDocument({documentId}));
  }

  uploadingSucceed(importedDoc: ImportedDoc) {
    this.store.dispatch(uploadingFileToDocumentSuccess({importedDoc}));
  }

  uploadingFailed(documentId: string) {
    this.store.dispatch(uploadingFileToDocumentFailure({documentId}));
  }
}
