import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-partners',
  template: `
    <div class="partners">
      <div fxLayout="row" fxLayoutAlign="none center" class="partners-title">
        <span class="line" fxHide fxShow.gt-xs></span>
        <h3>Ils nous font confiance</h3>
        <span class="line" fxHide fxShow.gt-xs></span>
      </div>
      <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center center" class="partners-logo">
        <img src="assets/img/tax/boschetti.png" alt="Fiduciaire Boschetti">
        <img src="assets/img/tax/craftslab.png" alt="CraftsLab">
        <img src="assets/img/tax/socraft.png" alt="SoCraft">
      </div>
    </div>
  `,
  styleUrls: ['partners.component.scss']
})
export class PartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
