import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxFormSummary } from './model/tax-form-summary.model';
import { select, Store } from '@ngrx/store';
import { getUserStatuses } from './store/admin.actions';
import { getUserStatusSelector } from './store/admin.selector';

@Component({
  selector: 'leaf-admin-user-status',
  template: `
    <div class="container">
      <h3>LEAF users</h3>
      <leaf-admin-user-summary *ngFor="let summary of (taxFormSummaries$ | async)"
                               [taxFormSummary]="summary"
      ></leaf-admin-user-summary>
    </div>
  `,
  styles: [`
    .container {
      display: flex;
      flex-direction: column;
    }
  `]
})
export class UserStatusComponent implements OnInit {

  taxFormSummaries$: Observable<TaxFormSummary[]>;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.taxFormSummaries$ = this.store.pipe(
      select(getUserStatusSelector)
    );
    this.store.dispatch(getUserStatuses());
  }

}
