import { createAction, props } from '@ngrx/store';
import { TaxDocumentsForm, TaxProfileForm } from '../model/form.model';

export const startTaxProfile = createAction(
  '[Form] start tax profile',
);

export const submitTaxProfile = createAction(
  '[Form] submit tax profile',
  props<{ taxProfileForm: TaxProfileForm }>()
);

export const changeResidency = createAction(
  '[Form] change residency',
  props<{ residency: string }>()
);

export const changeNationality = createAction(
  '[Form] change nationality',
  props<{ nationality: boolean }>()
);

export const changeChildren = createAction(
  '[Form] change children',
  props<{ children: boolean }>()
);

export const changePropertyOwner = createAction(
  '[Form] change property owner',
  props<{ propertyOwner: boolean }>()
);

export const changePropertyNumber = createAction(
  '[Form] change property number',
  props<{ propertyNumber: number }>()
);

export const changeInvestments = createAction(
  '[Form] change investments',
  props<{ investments: string }>()
);

export const startTaxDocumentsForm = createAction(
  '[Form] start tax documents form',
);

export const submitTaxDocumentsForm = createAction(
  '[Form] submit tax documents form',
  props<{ taxDocumentsForm: TaxDocumentsForm }>()
);

export const changeValueTaxDocumentsForm = createAction(
  '[Form] change in tax documents form',
  props<{ controlName: string, value: boolean | number | string }>()
);
