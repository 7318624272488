import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Question } from '../../model/form.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'leaf-yes-or-no-simple',
  template: `
    <div [formGroup]="parentForm">
      <h3>{{ question.title }}</h3>
      <p *ngIf="question.hint" class="hint">{{ question.hint }}</p>
      <mat-button-toggle-group
        [formControlName]="question.controlName"
        [value]="value"
        (change)="valueChanged($event.value)">
        <mat-button-toggle class="yes" [value]="true"><mat-icon matPrefix>check</mat-icon>Oui</mat-button-toggle>
        <mat-button-toggle class="no" [value]="false"><mat-icon matPrefix>close</mat-icon>Non</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  `,
  styleUrls: [
    './yes-or-no-simple.component.scss',
    './../common/common.question.scss',
  ]
})
export class YesOrNoSimpleComponent {

  @Input() question: Question;
  @Input() parentForm: FormGroup;
  @Input() value: boolean;

  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {
  }

  valueChanged(value: boolean) {
    this.valueChange.emit(value);
  }
}
