import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { DatastoreService } from '../service/datastore.service';
import {
  getProfile,
  getProfileSuccess,
  getTaxDocumentsData,
  getTaxDocumentsDataFailure,
  getTaxDocumentsDataSuccess,
  getTaxProfileData,
  getTaxProfileDataFailure,
  getTaxProfileDataSuccess,
  refreshProfileAfterPayment,
  refreshProfileSuccess,
  saveTaxDocumentsData,
  saveTaxDocumentsDataSuccess,
  saveTaxProfileData,
  saveTaxProfileDataSuccess
} from './datastore.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { State } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { getAuthUser } from '../../authentication/store/authentication.selector';
import { User } from '../../authentication/model/user.model';
import {
  getUserAfterSignInSuccess,
  getUserAfterSignUpSuccess,
  getUserSuccess,
} from '../../authentication/store/authentication.actions';
import {
  observationChanged,
  observationChangedSuccess,
  uploadingFileToDocumentSuccess
} from '../../documents/store/documents.actions';
import { getTaxProfileForm } from '../../form/store/form.selector';
import { TaxProfileForm } from '../../form/model/form.model';
import { of } from 'rxjs';
import { submitTaxDocumentsForm, submitTaxProfile } from '../../form/store/form.actions';
import { Profile } from '../../profile/model/profile.model';
import { saveProfile, saveProfileSuccess } from '../../profile/store/profile.actions';

@Injectable({
  providedIn: 'root'
})
export class DatastoreEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>,
    private datastoreService: DatastoreService,
  ) {
  }

  dispatchSaveTaxProfileDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getUserAfterSignUpSuccess.type, getUserAfterSignInSuccess.type),
    withLatestFrom(this.store.pipe(select(getTaxProfileForm))),
    map(([, taxProfileForm]: [any, TaxProfileForm]) => saveTaxProfileData({taxProfileForm}))
  ));

  saveTaxProfileDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(saveTaxProfileData.type, submitTaxProfile.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([action, user]: [ReturnType<typeof saveTaxProfileData>, User]) =>
      this.datastoreService.saveTaxProfileData(action.taxProfileForm, user)
    ),
    map(() => saveTaxProfileDataSuccess())
  ));

  loadTaxProfileDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getTaxProfileData.type, getUserAfterSignInSuccess.type, getUserSuccess.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([, user]: [any, User]) =>
      this.datastoreService.loadTaxProfileData(user).pipe(
        map((taxProfileForm) => getTaxProfileDataSuccess({taxProfileForm})),
        catchError(() => of(getTaxProfileDataFailure()))
      )
    )
  ));

  saveTaxDocumentsDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(saveTaxDocumentsData.type, submitTaxDocumentsForm.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([action, user]: [ReturnType<typeof saveTaxDocumentsData>, User]) =>
      this.datastoreService.saveTaxDocumentsData(action.taxDocumentsForm, user)
    ),
    map(() => saveTaxDocumentsDataSuccess())
  ));

  loadTaxDocumentsDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getTaxDocumentsData.type, getUserAfterSignInSuccess.type, getUserSuccess.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([, user]: [any, User]) =>
      this.datastoreService.loadTaxDocumentsData(user).pipe(
        map((taxDocumentsForm) => getTaxDocumentsDataSuccess({taxDocumentsForm})),
        catchError(() => of(getTaxDocumentsDataFailure()))
      )
    )
  ));

  uploadingFileToDocumentSuccessEffect$ = createEffect(() => this.actions$.pipe(
    ofType(uploadingFileToDocumentSuccess.type),
    mergeMap((action: ReturnType<typeof uploadingFileToDocumentSuccess>) =>
      this.datastoreService.saveImportedDocData(action.importedDoc)
    ),
    map(() => saveTaxDocumentsDataSuccess())
  ));

  observationChangedEffect$ = createEffect(() => this.actions$.pipe(
    ofType(observationChanged.type),
    mergeMap((action: ReturnType<typeof observationChanged>) =>
      this.datastoreService.saveObservationData(action.observation).pipe(
        map((documentId: string) => observationChangedSuccess({documentId})) // TODO Return two action for id 17 -> 1 and 7 WTF
      )
    )
  ));

  saveProfileEffect$ = createEffect(() => this.actions$.pipe(
    ofType(saveProfile.type),
    withLatestFrom(
      this.store.pipe(select(getAuthUser))
    ),
    mergeMap(([action, user]: [ReturnType<typeof saveProfile>, User]) =>
      this.datastoreService.saveProfileData(action.profile, user)
    ),
    map(() => saveProfileSuccess())
  ));

  loadProfileEffect$ = createEffect(() => this.actions$.pipe(
    ofType(
      getProfile.type,
      getUserAfterSignInSuccess.type,
      getUserSuccess.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([, user]) => this.datastoreService.loadProfileData(user)),
    map((profile: Profile) => getProfileSuccess({profile}))
  ));

  refreshProfileAfterPaymentEffect$ = createEffect(() => this.actions$.pipe(
    ofType(refreshProfileAfterPayment.type),
    withLatestFrom(this.store.pipe(select(getAuthUser))),
    mergeMap(([, user]) => this.datastoreService.loadProfileData(user)),
    map((profile: Profile) => refreshProfileSuccess({profile}))
  ));

}
