import { Action, createReducer, on } from '@ngrx/store';
import { Activity, Document, Status } from '../model/documents.model';
import { signOutSuccess } from '../../authentication/store/authentication.actions';
import {
  documentListIdentified,
  importedDocumentsLinked,
  observationChanged, observationChangedSuccess,
  observationsLinked,
  uploadingFileToDocument,
  uploadingFileToDocumentFailure,
  uploadingFileToDocumentSuccess
} from './documents.actions';

export default interface DocumentsState {
  documents: Document[];
  activities: Activity[];
}

export const initialState: DocumentsState = {
  documents: [],
  activities: [
    { name: 'Déclaration fiscale 2020', idList: [] }
  ]
};

const documentsReducer = createReducer(
  initialState,
  on(
    documentListIdentified,
    (state, action) => (
      {...state, documents: action.documents, activities: action.activities}
    )),
  on(
    importedDocumentsLinked, observationsLinked,
    (state, action) => (
      {...state, documents: action.documents}
    )),
  on(
    uploadingFileToDocument,
    (state, action) => (
      {...state, documents: state.documents.map(document => {
          if (document.id === action.documentId) {
            return {...document, status: Status.Uploading};
          }
          return document;
        })}
    )),
  on(
    uploadingFileToDocumentSuccess,
    (state, action) => (
      {...state, documents: state.documents.map(document => {
          if (document.id === action.importedDoc.documentId) {
            return {...document,
              status: document.files.length + 1,
              lastChange: action.importedDoc.lastChange,
              files: document.files.concat({name: action.importedDoc.name, path: action.importedDoc.path})
            };
          }
          return document;
        })}
    )),
  on(
    uploadingFileToDocumentFailure,
    (state, action) => (
      {...state, documents: state.documents.map(document => {
          if (document.id === action.documentId) {
            return {...document, status: Status.Failed};
          }
          return document;
        })}
    )),
  on(
    observationChanged,
    (state, action) => (
      {...state, documents: state.documents.map(document => {
          if (document.id === action.observation.documentId) {
            return {...document, observation: action.observation.text, observationStatus: 'enregistrement...' };
          }
          return document;
        })}
    )),
  on(
    observationChangedSuccess,
    (state, action) => (
      {...state, documents: state.documents.map(document => {
          if (document.id === action.documentId) {
            return {...document, observationStatus: 'enregistré' };
          }
          return document;
        })}
    )),
  on(
    signOutSuccess,
    (state) => (
      {...state, documents: null, activities: null}
    ))
);

export function reducer(state: DocumentsState | undefined, action: Action) {
  return documentsReducer(state, action);
}
