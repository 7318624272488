import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NavigationRailComponent } from './navigation-rail/navigation-rail.component';

@Component({
  selector: 'leaf-root',
  template: `
    <leaf-top-bar></leaf-top-bar>
    <leaf-navigation-rail [path]="path$ | async" #nav>
      <router-outlet (activate)="onActivate()"></router-outlet>
    </leaf-navigation-rail>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('nav') nav: NavigationRailComponent;

  path$: Observable<string>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.path$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route.url;
      }),
      mergeMap((url): Observable<string> => url.pipe(
        map((urlSegments: UrlSegment[]): string => {
          if (urlSegments?.length) {
            return urlSegments[urlSegments.length - 1].path;
          }
        })
      ))
    );
  }

  onActivate() {
    this.nav.content.nativeElement.scrollTop = 0;
  }
}
