import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question } from '../../model/form.model';

@Component({
  selector: 'leaf-choice-simple',
  template: `
    <div [formGroup]="parentForm">
      <h3>{{ question.title }}</h3>
      <span *ngIf="question.hint" class="hint">{{ question.hint }}</span>
      <mat-radio-group
        [formControlName]="question.controlName"
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        color="primary"
        (change)="inputChange($event.value)">
        <mat-radio-button class="example-radio-button" *ngFor="let value of values" [value]="value">
          {{value}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styles: [`
    mat-radio-group {
      display: flex;
      flex-direction: column;
      padding-bottom: 6px;
    }

    mat-radio-button {
      font-size: 14px;
      height: 48px;
      line-height: 48px;
    }
  `],
  styleUrls: ['./../common/common.question.scss']
})
export class ChoiceSimpleComponent {

  @Input() question: Question;
  @Input() parentForm: FormGroup;
  @Input() values: [string];

  @Output() choiceChange = new EventEmitter<string>();

  constructor() {
  }

  inputChange(value: string) {
    this.choiceChange.emit(value);
  }

}
