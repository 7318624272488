import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AdminService } from '../service/admin.service';
import { getSelectedUser, getSelectedUserSuccess, getUserStatuses, getUserStatusesSuccess } from './admin.actions';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminEffects {

  constructor(
    private actions$: Actions,
    private adminService: AdminService,
  ) {
  }

  getUserStatusEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getUserStatuses.type),
    mergeMap(() => this.adminService.getUserStatuses()),
    map((statuses) => getUserStatusesSuccess({statuses}))
  ));

  getSelectedUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getSelectedUser.type),
    mergeMap((userId: string) => this.adminService.getUser(userId)),
    map((user) => getSelectedUserSuccess({user}))
  ));

}
