import { Component, Input } from '@angular/core';

@Component({
  selector: 'leaf-why-item',
  template: `
    <div>
      <img src="{{imagePath}}" alt="{{imagePath}}"/>
      <h1>{{title}}</h1>
      <span>{{text}}</span>
    </div>
  `,
  styles: [`
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 7.7%;
    }
    img {
      width: 100%;
    }
    h1 {
      font-size: 30px;
      font-weight: bold;
      margin: 10px 0;
    }
    span {
      font-size: 20px;
      margin-bottom: 30px;
      text-align: center;
    }
  `]
})
export class WhyItemComponent {

  @Input() imagePath: string;
  @Input() title: string;
  @Input() text: string;

  constructor() {
  }

}
