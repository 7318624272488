import { Injectable } from '@angular/core';
import { AnalyticsService } from '../service/analytics.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  startTaxProfile,
  submitTaxProfile,
  changeResidency,
  changeNationality,
  changeChildren,
  changePropertyOwner,
  changePropertyNumber,
  changeInvestments,
  startTaxDocumentsForm,
  submitTaxDocumentsForm,
  changeValueTaxDocumentsForm,
} from '../../form/store/form.actions';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import {
  forgotPassword,
  missingDocumentClick,
  signIn,
  signUp
} from '../../authentication/store/authentication.actions';
import { uploadingFileToDocument } from '../../documents/store/documents.actions';
import {
  createToken,
  paymentConfirmed,
  paymentFailed
} from '../../payment/store/payment.actions';
import { faqClicked } from '../../content/store/content.actions';
import { navClicked } from '../../navigation-rail/store/navigation-rail.actions';
import { callToActionClicked } from '../../tax-home/store/tax-home.actions';
import { activationClicked, priceFixed } from '../../activation/store/activation.actions';
import { State } from '../../reducers';
import { isAdminSelector } from '../../profile/store/profile.selector';

@Injectable({
  providedIn: 'root'
})
export class LogEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private analyticsService: AnalyticsService,
  ) {
  }

  logEffect$ = createEffect(() => this.actions$.pipe(
    ofType(
      startTaxProfile.type,
      submitTaxProfile.type,
      changeResidency.type,
      changeNationality.type,
      changeChildren.type,
      changePropertyOwner.type,
      changePropertyNumber.type,
      changeInvestments.type,
      priceFixed.type,

      startTaxDocumentsForm.type,
      submitTaxDocumentsForm.type,
      changeValueTaxDocumentsForm.type,

      callToActionClicked.type,
      activationClicked.type,
      faqClicked.type,
      navClicked.type,

      createToken.type,
      paymentConfirmed.type,
      paymentFailed.type,

      uploadingFileToDocument.type,

      forgotPassword.type,
      missingDocumentClick.type,
      signIn.type,
      signUp.type,
    ),
    withLatestFrom(this.store.pipe(select(isAdminSelector))),
    tap(([action, isAdmin]: [Action, boolean]) => this.analyticsService.logAction(action, isAdmin))
  ), {dispatch: false});

  userPropertiesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(
      changeResidency.type,
      changeNationality.type,
      changeChildren.type,
      changePropertyOwner.type,
      changePropertyNumber.type,
      changeInvestments.type,
      priceFixed.type,
      changeValueTaxDocumentsForm.type,
      paymentConfirmed.type,
    ),
    withLatestFrom(this.store.pipe(select(isAdminSelector))),
    tap(([action, isAdmin]: [Action, boolean]) => this.analyticsService.updateUserProperties(action, isAdmin))
  ), {dispatch: false});
}
