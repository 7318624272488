import { createAction, props } from '@ngrx/store';

export const priceFixed = createAction(
  '[Activation] price fixed',
  props<{ price: number }>()
);

export const activationClicked = createAction(
  '[Activation] button clicked',
  props<{ price: number, buttonId: string }>()
);
