import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-how',
  template: `
    <div class="how" [ngClass]="{'light': light}">
      <h1>Comment ça marche ?</h1>
      <img *ngIf="light" src="assets/img/tax/how-it-works-light.png" alt="Comment ça marche"/>
      <img *ngIf="!light" src="assets/img/tax/how-it-works-dark.png" alt="Comment ça marche"/>
      <p>Facile !</p>
    </div>
  `,
  styleUrls: ['how.component.scss']
})
export class HowComponent implements OnInit {

  @Input() light: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
