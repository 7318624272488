import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getProfileSelector } from '../profile/store/profile.selector';
import { Profile } from '../profile/model/profile.model';
import { getProfile } from '../datastore/store/datastore.actions';

@Injectable({
  providedIn: 'root'
})
export class PaymentGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  canActivate(): Observable<boolean> | boolean {
    return this.getFromStoreOrAPI().pipe(
      switchMap((profilePaid: boolean) => {
        if (!profilePaid) {
          this.redirect();
        }
        return of(true);
      }),
      catchError(() => this.redirect())
    );
  }

  private redirect(): Observable<boolean> {
    this.router.navigate(['/pay'], { replaceUrl: true });
    return of(false);
  }

  private getFromStoreOrAPI(): Observable<boolean> {
    return this.store
      .select(getProfileSelector)
        .pipe(
          tap((profile: Profile) => {
            if (!profile) {
              this.store.dispatch(getProfile());
            }
          }),
          filter((profile: Profile) => profile !== null),
          map((profile: Profile) => profile.paid),
          take(1)
        );
  }
}
