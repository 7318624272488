<div class="container center">
  <mat-horizontal-stepper linear #stepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step *ngFor="let section of formSections; let i = index; let first = first" [stepControl]="formGroups[i]" editable>
      <form [formGroup]="formGroups[i]">
        <ng-template matStepLabel>{{ section.title }}</ng-template>
        <h2 *ngIf="!first">Durant l'année fiscale {{ formGroups[0].controls.taxYear.value }}</h2>
        <p *ngIf="i === INDEX_PROPERTY">Vous avez déclaré {{ property }}</p>
        <p *ngIf="i === INDEX_FAMILY">Vous avez déclaré {{ children }}</p>
        <ng-container *ngFor="let question of section.questions">
          <leaf-amount-simple *ngIf="question.type === 'amount'"
                              [question]="question"
                              [parentForm]="formGroups[i]"
                              (amountChanged)="valueChanged(question.controlName, $event)"
          ></leaf-amount-simple>
          <leaf-checklist-simple *ngIf="question.type === 'checklist'"
                                 [question]="question"
                                 [values]="question.options"
                                 [parentForm]="formGroups[i]"
                                 (listChange)="valueChanged(question.controlName, $event)"
          ></leaf-checklist-simple>
          <leaf-yes-or-no-simple *ngIf="question.type === 'yes-or-no'"
                                 [question]="question"
                                 [parentForm]="formGroups[i]"
                                 (valueChange)="valueChanged(question.controlName, $event)"
          ></leaf-yes-or-no-simple>
          <p *ngIf="question.controlName === MARRIED_QUESTION && formGroups[i].controls[question.controlName].value">Veuillez répondre aux questions en prenant en compte votre situation et celle de votre partenaire.</p>
        </ng-container>
        <button mat-flat-button
                color="primary"
                type="button"
                [disabled]="formGroups[i].invalid"
                matStepperNext>Suivant</button>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Récapitulatif</ng-template>
      <p>Veuillez s'il vous plaît contrôler les données fournies avant de valider le formulaire</p>
      <leaf-overview [taxDocumentsForm]="taxDocumentsForm"></leaf-overview>
      <button mat-flat-button
              color="primary"
              type="button"
              (click)="formSubmit()">Terminer</button>
    </mat-step>
  </mat-horizontal-stepper>
</div>
