import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { HowComponent } from './how/how.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PartnersComponent } from './partners/partners.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { TermsComponent } from './legal/terms.component';
import { MatButtonModule } from '@angular/material/button';
import { InfoPaymentComponent } from './info-payment/info-payment.component';
import { WhyItemComponent } from './why/why-item.component';
import { MotionDesignComponent } from './motion-design/motion-design.component';
import { FaqComponent } from './faq/faq.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    FooterComponent,
    HowComponent,
    InfoPaymentComponent,
    PartnersComponent,
    TermsComponent,
    TestimonialComponent,
    WhyItemComponent,
    MotionDesignComponent,
    FaqComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatExpansionModule,
    FontAwesomeModule
  ],
  exports: [
    FooterComponent,
    HowComponent,
    InfoPaymentComponent,
    PartnersComponent,
    TermsComponent,
    TestimonialComponent,
    WhyItemComponent,
    MotionDesignComponent,
    FaqComponent,
  ]
})
export class ContentModule { }
