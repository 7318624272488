import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-info-payment',
  template: `
    <div class="info-payment"
         fxLayout="column"
         fxLayout.gt-sm="row"
         fxLayoutAlign="space-between center"
         [ngClass]="{'light': light}">
      <div>
        <img *ngIf="!light" src="assets/img/tax/secure-dark.png"/>
        <img *ngIf="light" src="assets/img/tax/secure-light.png"/>
        <div>
          <h4>Paiement sécurisé</h4>
          <p>Avec Stripe, vos données et informations de paiement sont en sécurité.</p>
        </div>
      </div>
      <div><img src="assets/img/tax/payment-methods.png"/></div>
      <div>
        <img *ngIf="!light" src="assets/img/tax/refund-dark.png"/>
        <img *ngIf="light" src="assets/img/tax/refund-light.png"/>
        <div>
          <h4>Satisfait ou remboursé</h4>
          <p>C’est notre engagement 100% satisfaction*</p>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['info-payment.component.scss']
})
export class InfoPaymentComponent implements OnInit {

  @Input() light: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
