import { ActionReducerMap } from '@ngrx/store';
import ActivationState from '../activation/store/activation.reducer';
import AuthenticationState from '../authentication/store/authentication.reducer';
import DocumentsState from '../documents/store/documents.reducer';
import FormState from '../form/store/form.reducer';
import * as fromActivation from '../activation/store';
import * as fromAdmin from '../admin/store/admin.reducer';
import * as fromAuth from '../authentication/store';
import * as fromDocuments from '../documents/store';
import * as fromForm from '../form/store';
import * as fromPayment from '../payment/store/payment.reducer';
import * as fromProfile from '../profile/store/profile.reducer';
import ProfileState from '../profile/store/profile.reducer';
import PaymentState from '../payment/store/payment.reducer';
import AdminState from '../admin/store/admin.reducer';

export interface State {
  activation: ActivationState;
  admin: AdminState;
  authentication: AuthenticationState;
  documents: DocumentsState;
  form: FormState;
  payment: PaymentState;
  profile: ProfileState;
}

export const reducers: ActionReducerMap<State> = {
  activation: fromActivation.reducer,
  admin: fromAdmin.reducer,
  authentication: fromAuth.reducer,
  documents: fromDocuments.reducer,
  form: fromForm.reducer,
  payment: fromPayment.reducer,
  profile: fromProfile.reducer,
};
