import { createAction, props } from '@ngrx/store';
import { Profile } from '../model/profile.model';

export const goToProfile = createAction(
  '[Profile] show'
);

export const saveProfile = createAction(
  '[Profile] save profile',
  props<{ profile: Profile }>()
);

export const saveProfileSuccess = createAction(
  '[Profile] save profile success'
);
