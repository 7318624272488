import { Action, createReducer, on } from '@ngrx/store';
import { priceFixed } from './activation.actions';
import { signOutSuccess } from '../../authentication/store/authentication.actions';

export default interface ActivationState {
  price: number;
}

export const initialState: ActivationState = {
  price: null,
};

const activationReducer = createReducer(
  initialState,
  on(
    priceFixed,
    (state, action) => (
      {...state, price: action.price}
    )),
  on(
    signOutSuccess,
    (state) => (
      {...state, price: null}
    )),
);

export function reducer(state: ActivationState | undefined, action: Action) {
  return activationReducer(state, action);
}
