import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { createToken } from './store/payment.actions';
import { Element as StripeElement, ElementOptions, ElementsOptions, } from '@nomadreservations/ngx-stripe';
import { getProfileSelector } from '../profile/store/profile.selector';
import { Observable, Subscription } from 'rxjs';
import { getPrice } from '../activation/store/activation.selector';
import { getCurrentPaymentState } from './store/payment.selector';
import { paymentCompleted, paymentError, paymentIdle, paymentProcessing } from './model/payment.model';
import { getTaxProfileForm } from '../form/store/form.selector';
import { TaxProfileForm } from '../form/model/form.model';
import { goToProfile } from '../profile/store/profile.actions';

@Component({
  selector: 'leaf-pay',
  template: `
    <div *ngIf="amount$ | async" class="container">
      <h1 style="font-weight: bold">
        Payez votre déclaration <span style="color: deepskyblue">en toute sécurité</span>
      </h1>
      <p>Veuillez régler le montant indiqué par carte bancaire afin de profiter de nos services pour votre déclaration
        fiscale.</p>
      <div class="payment" fxLayout="column" fxLayout.gt-xs="row">
        <div class="card-info">
          <h3>Informations de paiement</h3>
          <p>Veuillez fournir les informations de votre carte bancaire.</p>
          <ngx-stripe-card [elementTypes]="['card']"
                           [options]="cardOptions"
                           [elementsOptions]="elementsOptions"
                           (change)="cardUpdated($event)"></ngx-stripe-card>
        </div>
        <div class="order-info">
          <h3>Montant à payer</h3>
          <span>CHF {{amount$ | async}}.―</span>
          <hr>
          <p>Déterminé sur la base des informations reçues. Vous avez déclaré:</p>
          <leaf-tax-profile [taxProfile]="taxProfile$ | async"></leaf-tax-profile>
        </div>
      </div>
      <div class="actions" *ngIf="(paymentState$ | async) === idle || (paymentState$ | async) === error">
        <div>
          <button mat-button routerLink="/activation">Retour</button>
        </div>
        <button mat-flat-button
                color="primary"
                class="payment-button"
                (click)="createToken()"
                [disabled]="!complete">Payer
        </button>
        <span></span>
      </div>
      <p class="authorization" *ngIf="(paymentState$ | async) === processing">
        <span style="color: deepskyblue">Autorisation</span> en cours…
      </p>
      <p class="authorization" *ngIf="(paymentState$ | async) === error">
        <span style="color: deepskyblue">Échec</span> du paiement
      </p>
      <p class="authorization" *ngIf="(paymentState$ | async) === completed">
        <span style="color: deepskyblue">Paiement</span> effectué<br/><br/>
        <button mat-button color="primary" routerLink="/profile">Accéder au dashboard</button>
      </p>
      <leaf-info-payment></leaf-info-payment>
    </div>
  `,
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit, OnDestroy {

  amount$: Observable<number>;
  paymentState$: Observable<string>;
  taxProfile$: Observable<TaxProfileForm>;
  profileSubscription: Subscription;

  idle = paymentIdle;
  processing = paymentProcessing;
  error = paymentError;
  completed = paymentCompleted;

  complete = false;
  element: StripeElement;
  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: '#276fd3',
        color: '#31325F',
        lineHeight: '40px',
        fontFamily: '"Helvetica Neue", Roboto, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    },
    hidePostalCode: true,
    hideIcon: false,
    iconStyle: 'default'
  };

  elementsOptions: ElementsOptions = {
    locale: 'fr'
  };

  constructor(
    private store: Store
  ) {
  }

  ngOnInit() {
    this.paymentState$ = this.store.pipe(select(getCurrentPaymentState));
    this.amount$ = this.store.pipe(select(getPrice));
    this.taxProfile$ = this.store.pipe(select(getTaxProfileForm));
    this.profileSubscription = this.store.pipe(select(getProfileSelector))
      .subscribe(profile => {
        if (profile && profile.paid) {
          this.store.dispatch(goToProfile());
        }
      });
  }

  ngOnDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  cardUpdated(result) {
    this.element = result.element;
    this.complete = result.complete;
  }

  createToken() {
    this.store.dispatch(createToken({element: this.element}));
  }
}
