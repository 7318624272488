import { NgModule } from '@angular/core';
import { InstanceOfDatePipe, TimestampToStringPipe, TypeOfPipe } from './type.pipe';

@NgModule({
  declarations: [
    TypeOfPipe,
    InstanceOfDatePipe,
    TimestampToStringPipe,
  ],
  imports: [
  ],
  exports: [
    TypeOfPipe,
    InstanceOfDatePipe,
    TimestampToStringPipe,
  ]
})
export class TypePipeModule {
}
