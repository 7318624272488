import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationComponent } from './validation.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { StringPipeModule } from '../shared/string.module';



@NgModule({
  declarations: [ValidationComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    StringPipeModule
  ]
})
export class ValidationModule { }
