
export class Profile {
  admin: boolean;
  paid: boolean;
  taxValidated: boolean;
  taxValidationDate: Date;

  constructor(
    paid: boolean
  ) {
    this.admin = false;
    this.paid = paid;
    this.taxValidated = false;
    this.taxValidationDate = null;
  }
}
