import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'leaf-motion-design',
  template: `
    <div class="video-container">
      <video poster="assets/img/motion-design-preview.png" controls>
        <source src="assets/vid/motion-design-leaf.mp4" type="video/mp4">
      </video>
    </div>
  `,
  styles: [`
    .video-container {
      width: 100%;
      padding-top: 56.25%;
      height: 0;
      position: relative;
    }

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  `]
})
export class MotionDesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
