import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationRailComponent } from './navigation-rail.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ArrayPipeModule } from '../shared/array.module';



@NgModule({
  declarations: [NavigationRailComponent],
  exports: [
    NavigationRailComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    ArrayPipeModule
  ]
})
export class NavigationRailModule { }
