import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getAuthUser } from '../authentication/store/authentication.selector';
import { isAdmin } from './service/admin.helper';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private store: Store,
  ) {
  }

  canActivate(): Observable<boolean> | boolean {
    return this.isAdmin();
  }

  isAdmin(): Observable<boolean> {
    return this.store.pipe(
      select(getAuthUser),
      map(user => isAdmin(user.email))
    );
  }
}
