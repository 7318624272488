import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { DocumentsModule } from '../documents/documents.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexModule } from '@angular/flex-layout';
import { FormModule } from '../form/form.module';



@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    DocumentsModule,
    FlexModule,
    FormModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    RouterModule,
  ]
})
export class ProfileModule { }
