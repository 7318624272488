import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import { time } from 'jasmine-marbles';

@Pipe({
  name: 'typeof'
})
export class TypeOfPipe implements PipeTransform {

  transform(value: any, typeName: string): boolean {
    return typeof value === typeName;
  }
}

@Pipe({
  name: 'instanceofDate'
})
export class InstanceOfDatePipe implements PipeTransform {

  transform(value: any): boolean {
    return value instanceof Date;
  }
}

@Pipe({
  name: 'timestampToString'
})
export class TimestampToStringPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const seconds = value._seconds;
    return seconds ? this.formatTimestamp(new Timestamp(seconds, 0)) : undefined;
  }

  formatTimestamp(timestamp: Timestamp): string {
    const date = timestamp.toDate();
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

}
